<template>
  <v-container>
    <h1 class="mt-5">Muokkaa asiakasta</h1>
    <v-form ref="form">
      <v-container>
        <v-row>
          <v-col cols="12" md="4">
            <v-radio-group v-model="customerData.isCompany" :mandatory="true">
              <v-radio label="Yksityinen" :value="false"></v-radio>
              <v-radio label="Yritys" :value="true"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field v-model="customerData.name" :rules="required" label="Nimi"></v-text-field>
          </v-col>

          <v-col v-if="customerData.isCompany" cols="12" md="4">
            <v-text-field v-model="customerData.businessId" label="Y-tunnus"></v-text-field>
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field v-model="customerData.email" label="Email"></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="4">
            <v-text-field v-model="customerData.address" label="Osoite"></v-text-field>
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field v-model="customerData.zipCode" label="Postinumero"></v-text-field>
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field v-model="customerData.city" label="Kaupunki"></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="4">
            <v-text-field v-model="customerData.phone" label="Puhelin"></v-text-field>
          </v-col>
        </v-row>

        <v-btn color="success" class="mr-4" @click="submit">Tallenna</v-btn>
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
import axiosMethods from '../../mixins/axios';
import mixins from '../../mixins/mixins';

export default {
  title: 'Muokkaa asiakasta',
  mixins: [mixins],
  data() {
    return {
      customerData: {},
      emailRules: [(v) => /.+@.+\..+/.test(v) || 'Email ei ole oikeassa muodossa'],
      required: [(v) => !!v || 'Pakollinen kenttä'],
      positiveNumber: [(v) => v > 0 || 'Anna positiivinen luku'],
    };
  },
  async created() {
    try {
      let res = await axiosMethods.get('/api/v1/customer/getOne/' + this.$route.params.id);
      this.customerData = res.data.data;
    } catch (err) {
      this.showPopup(err, 'error');
    }
  },
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        try {
          await axiosMethods.updateOne('/api/v1/customer/update/' + this.$route.params.id, this.customerData);
          this.showPopup('Asiakas tallennettu', 'success');
        } catch (err) {
          this.showPopup(err, 'error');
        }
      }
    },
  },
};
</script>

<style></style>
