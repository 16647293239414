<template>
  <v-container>
    <div class="mt-5">
      <!-- PPRODUCTS HEADER -->
      <v-card max-width="800" class="mx-auto">
        <v-list>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>
                <h3 class="font-weight-light">Ostoslista</h3>
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon class="mr-5" icon>mdi-cart</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <!-- LIST OF PRODUCTS -->
        <v-list>
          <div v-for="(product, index) in products" :key="index">
            <v-list-item>
              <v-list-item-content class="content-wrapper">
                <v-row no-gutters>
                  <v-col md="5" @click="toggle(index)">
                    <div>
                      <v-list-item-content>
                        <v-list-item-title style="font-size: 18px">
                          {{product.name}} |
                          <span
                            style="color: #4caf50; font-size: 18px"
                          >{{product.price}}</span>
                        </v-list-item-title>
                        <v-list-item-subtitle>Tuotenumero: {{product.productId}}</v-list-item-subtitle>
                        <v-list-item-subtitle>Kauppias: {{product.createdBy.name}}</v-list-item-subtitle>
                      </v-list-item-content>
                    </div>
                  </v-col>

                  <v-col cols="1">
                    <div class="field-wrapper text-center">
                      <v-list-item-title class="ml-3">
                        <v-icon
                          title="Lisätiedot"
                          color="green"
                          @click="toggle(index)"
                          style="cursor: pointer;"
                        >mdi-arrow-down</v-icon>
                      </v-list-item-title>
                    </div>
                  </v-col>

                  <v-col cols="2">
                    <div class="field-wrapper text-center">
                      <v-list-item-title>{{product.quantity}} kpl</v-list-item-title>
                    </div>
                  </v-col>

                  <v-col cols="1">
                    <div>
                      <v-btn @click="$store.commit('addToCart', product)" text>
                        <v-icon color="#1976D2" small>mdi-plus</v-icon>
                      </v-btn>
                    </div>
                    <div>
                      <v-btn @click="$store.commit('decreaseQuantity', product)" text class="mt-1">
                        <v-icon color="#1976D2" small>mdi-minus</v-icon>
                      </v-btn>
                    </div>
                  </v-col>

                  <v-col cols="3">
                    <div
                      class="field-wrapper"
                      @click="deleteItem(product)"
                      style="color: red; cursor: pointer"
                      title="Poista ostoskorista"
                    >
                      <v-list-item-title class="text-right mr-6">X</v-list-item-title>
                    </div>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>

            <v-row class="pa-3" v-if="activeProduct == index">
              <v-col cols="12" md="6">
                <v-textarea
                  rows="3"
                  placeholder="Tarkenna esim. haluamasi väri, koko tms."
                  label="Tarkemmat tuotetiedot"
                  :value="product.details"
                  @input="saveData(product, $event)"
                ></v-textarea>
              </v-col>
            </v-row>

            <v-divider class="mb-2" v-if="index + 1 < products.length"></v-divider>
          </div>
        </v-list>
      </v-card>
      <h2 class="text-center mt-10">Tiedustele ostoslistan tuotteiden saatavuutta</h2>
      <!-- Form -->
      <v-form ref="form">
        <v-row class="justify-center text-center">
          <v-col md="6">
            <p>
              Tiedustelu lähtee kaikille kauppiaille, joiden tuotteita ostoslistassasi on.
              <br />
              <br />Voit antaa lisätietoja ja haluamasi tuotemäärät riveittäin. Kauppiaat ovat yhteydessä sinuun mahdollisimman pian.
            </p>
            <v-btn @click="submit" class="mt-5" color="success">Kysy saatavuutta</v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </v-container>
</template>

<script>
import axiosMethods from "../../../mixins/axios";
import mixins from "../../../mixins/mixins";

export default {
  title: "Ostoskori",
  mixins: [mixins],
  data: () => {
    return {
      activeProduct: null
    };
  },
  computed: {
    products() {
      return this.$store.getters.getCart;
    }
  },
  methods: {
    showSnackbar(value) {
      this.color = value.color;
      this.text = value.text;
      this.snackbar = true;
    },
    deleteItem(item) {
      this.$store.commit("removeFromCart", item);
    },
    saveData(product, event) {
      let data = { product, event };
      this.$store.commit("saveProductInformation", data);
    },
    toggle(index) {
      if (this.activeProduct == null) {
        return (this.activeProduct = index);
      }
      if (this.activeProduct == index) {
        return (this.activeProduct = null);
      }
      this.activeProduct = index;
    },
    async submit() {
      try {
        if (this.products.length < 1) {
          return this.showPopup(
            "Lisää ainakin yksi tuote ostoslistaan",
            "error"
          );
        }
        await axiosMethods.createOne(
          "/api/v1/order/createOrder",
          this.products
        );
        this.$store.commit("emptyCart");
        this.$router.go();
        this.showPopup("Kysely(t) lähetetty", "success");
      } catch (err) {
        this.showPopup(err, "error");
      }
    }
  }
};
</script>

<style scoped>
.content-wrapper {
  padding: 0;
  margin: 0;
}

.field-wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.number-input >>> input {
  text-align: center;
}
</style>