<template>
  <v-container>
    <v-form ref="form">
      <v-container>
        <v-row class="justify-center">
          <v-col cols="10" md="7" lg="5" xl="4">
            <h1 class="mt-5 mb-5">Kirjaudu sisään</h1>
            <v-text-field v-model="email" label="Käyttäjätunnus"></v-text-field>
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col cols="10" md="7" lg="5" xl="4">
            <v-text-field v-model="password" type="password" label="Salasana"></v-text-field>
            <router-link to="/forgotpassword">
              <small>Unohditko salasanasi?</small>
            </router-link>
          </v-col>
        </v-row>

        <v-row class="justify-center">
          <v-col cols="10" md="7" lg="5" xl="4">
            <v-btn color="success" class="mr-4" @click.prevent="login">Kirjaudu</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
import mixins from "../../mixins/mixins";

export default {
  title: "Kirjaudu sisään",
  mixins: [mixins],
  data() {
    return {
      email: "",
      password: ""
    };
  },
  methods: {
    async login() {
      try {
        await this.$store.dispatch("getToken", {
          email: this.email,
          password: this.password
        });

        if (this.$store.getters.getRole == "admin") {
          this.$router.push("/invoices");
        } else {
          this.$router.push("/user/invoices");
        }
      } catch (err) {
        this.showPopup(err, "error");
      }
    }
  }
};
</script>

<style></style>
