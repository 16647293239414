const state = {
  tab: 0,
  dataTablePage: 1,
  itemsPerPage: 20,
  getPreviousPage: false,
  mainSearchValue: "",
  searchField: "",
  searchValue: "",
};

const mutations = {
  saveTabNum(state, tabNum) {
    state.tab = tabNum;
  },

  saveTableOptions(state, options) {
    state.getPreviousPage = false;
    state.dataTablePage = options.page;
    state.itemsPerPage = options.itemsPerPage;
  },

  saveMainSearchTerm(state, val) {
    state.mainSearchValue = val;
  },

  saveSearchTerm(state, data) {
    state.searchField = data.field;
    state.searchValue = data.val;
  },

  // controlled in app.vue window eventlistener for back and forward button
  returnPreviousPage(state) {
    state.getPreviousPage = true;
  },
};

export default {
  state,
  mutations,
  namespaced: true,
};
