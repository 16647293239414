<template>
  <v-container fluid>
    <div class="mt-5">
      <v-row class="justify-center">
        <v-col md="4">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Etsi"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        multi-sort
        :search="search"
        :headers="headers"
        :items="orders"
        show-expand
        :single-expand="singleExpand"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Tilaukset</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
          </v-toolbar>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td class="pb-3 pt-3" :colspan="headers.length">
            <div v-for="(product, index) in item.products" :key="index">
              <strong style="margin-bottom: -3px">
                {{ index + 1 }}.
                <span style="margin-bottom: -3px">{{ product.name }}</span>
              </strong>
              <p style="margin-bottom: -3px">
                Asiakkaan hinta:
                <span style="font-weight: 600">{{ formatPrice(product.price) }}</span>
              </p>
              <p style="margin-bottom: -3px">
                Määrä:
                <span style="font-weight: 600">{{ product.quantity }} kpl</span>
              </p>
              <small>{{ product.details }}</small>
            </div>
          </td>
        </template>

        <!-- dueDate -->
        <template v-slot:[`item.dueDate`]="{ item }">
          <span :class="{ isLate: item.isLate && item.paid === 'Ei' }">{{ item.dueDate }}</span>
        </template>
        <!-- Actions -->
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon title="Poista" small class="mr-5" @click="deleteItem(item)">mdi-delete</v-icon>
        </template>
        <template v-slot:no-data>
          <h2>Ei tilauksia</h2>
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>

<script>
import axiosMethods from "../../mixins/axios";
import mixins from "../../mixins/mixins";

export default {
  title: "Tilaukset",
  mixins: [mixins],
  data: () => ({
    singleExpand: true,
    search: "",
    headers: [
      {
        text: "Tilauksen numero",
        value: "orderNumber",
      },
      {
        text: "Tilauksen päivämäärä",
        value: "createdAt",
      },
      {
        text: "Profiili",
        value: "goesTo.name",
      },
      { text: "Asiakas", value: "createdBy.name" },
      { text: "Puhelin", value: "createdBy.phone" },
      { text: "Email", value: "createdBy.email" },

      {
        text: "Toiminnot",
        value: "actions",
        sortable: false,
      },
    ],
    orders: [],
  }),
  async created() {
    try {
      let res = await axiosMethods.get("/api/v1/order/getAll");
      let orders = res.data.orders;

      // Search form can only find these,
      orders = orders.map((el) => {
        el.price = this.formatPrice(el.price);
        el.createdAt = this.formatDate(el.createdAt);
        return el;
      });
      this.orders = orders;
    } catch (err) {
      this.showPopup(err, "error");
    }
  },
  methods: {
    async deleteItem(item) {
      if (confirm(`Haluatko varmasti poistaa tilauksen nro. ${item.orderNumber}?`)) {
        try {
          await axiosMethods.delete("/api/v1/order/deleteOne/" + item._id);
          this.$router.go();
          this.showPopup("Tilaus poistettu", "success");
        } catch (err) {
          this.showPopup(err, "error");
        }
      }
    },
  },
};
</script>

<style scoped></style>
