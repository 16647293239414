<template>
  <v-container fluid>
    <div class="mt-5">
      <v-row class="justify-center">
        <v-col md="4">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Etsi"
            single-line
            hide-details
            @keyup.enter="getDataFromApi()"
            @click:append="getDataFromApi()"
          ></v-text-field>
        </v-col>
        <v-col md="4">
          <v-checkbox v-model="expired" label="Näytä vain erääntyneet laskut"></v-checkbox>
        </v-col>
      </v-row>
      <v-data-table
        v-model="selected"
        multi-sort
        show-select
        :headers="headers"
        :items="invoices"
        :footer-props="footerProps"
        :items-per-page="20"
        :loading="loading"
        :options.sync="options"
        :server-items-length="totalLength"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Laskut</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>

            <!-- // PartPayment Dialog -->
            <v-dialog v-model="dialog" max-width="500px">
              <v-card>
                <v-card-title>
                  <span class="headline"
                    >Osamaksu laskulle nro. {{ partPayment.invoiceNumber }}</span
                  >
                </v-card-title>
                <v-container>
                  <v-tabs class="ml-2">
                    <v-tab @click="active = '1'">Maksa</v-tab>
                    <v-tab @click="active = '2'">Suoritukset</v-tab>
                  </v-tabs>
                </v-container>
                <!-- // Pay -->
                <v-card-text v-if="active == '1'">
                  <v-container>
                    <v-row>
                      <v-col>
                        <p>Kaikki summat ovat alvillisia</p>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        Summa:
                        <span class="popup-text">{{ partPayment.itemTotalAmount }}</span>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        Maksettu:
                        <span class="popup-text">{{ partPayment.alreadyPaid }}</span>
                      </v-col>
                      <v-col cols="12" sm="6" md="6">
                        Jäljellä:
                        <span class="popup-text">{{ partPayment.unPaid }}</span>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <v-text-field
                          step="0.01"
                          type="number"
                          v-model.number="partPayment.price"
                          label="Uusi maksu (sis alv)"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <!-- // Payments -->
                <v-card-text v-if="active == '2'">
                  <v-container>
                    <v-row>
                      <v-col>
                        <p>Kaikki summat ovat alvillisia</p>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <div v-for="(payment, i) in partialPayments" :key="i">
                          <span style="color: black">{{ formatDate(payment.createdAt) }}</span>
                          <br />
                          <p class="popup-text">
                            {{ payment.payment }} €
                            <span
                              @click="deletePartPayment(payment._id)"
                              style="color: red; cursor: pointer"
                              class="ml-5"
                              >X</span
                            >
                          </p>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="dialog = false">Peruuta</v-btn>
                  <v-btn color="blue darken-1" text @click="savePartPayment">Tallenna</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <router-link to="/invoice/create">
              <v-btn color="primary" dark class="mb-2">Uusi lasku</v-btn>
            </router-link>
          </v-toolbar>
        </template>

        <!-- TotalAmount -->
        <template #[`item.totalAmountWithTax`]="{ item }">
          <span>{{ formatPrice(totalAmountWithTax(item)) }}</span>
          <span
            v-if="!item.paid && totalAmountWithTax(item) > 0"
            @click="editPartPayment(item)"
            class="ml-2 partPayment"
            >(Osamaksu)</span
          >
        </template>

        <!-- dueDate -->
        <template v-slot:[`item.dueDate`]="{ item }">
          <span :class="{ isLate: item.isLate && !item.paid }">{{ formatDate(item.dueDate) }}</span>
        </template>

        <!-- createdAt -->
        <template v-slot:[`item.createdAt`]="{ item }">
          <span>{{ formatDate(item.createdAt) }}</span>
        </template>

        <!-- Paid -->
        <template v-slot:[`item.paid`]="{ item }">
          <span>{{ formatBoolean(item.paid) }}</span>
        </template>

        <!-- Send new invoice -->
        <template v-slot:[`item._id`]="{ item }">
          <span>
            <v-icon title="Lähetä sähköpostilla" small class="mr-7" @click="sendPdf(item)"
              >mdi-email-multiple-outline</v-icon
            >
          </span>
        </template>

        <!-- Show last send -->
        <template v-slot:[`item.lastSend`]="{ item }">
          <span>{{ formatDateInvoices(item.lastSend, item.sendState) }}</span>
        </template>

        <!-- Actions -->
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            v-if="!item.paid"
            title="Merkkaa maksetuksi"
            small
            class="mr-7 mt-4 pb-3"
            @click="markPayed(item)"
            >mdi-check</v-icon
          >
          <v-icon
            v-if="!item.paid"
            title="Muokkaa"
            small
            class="mr-7 mt-4 pb-3"
            @click="editItem(item)"
            >mdi-pencil</v-icon
          >

          <v-icon title="Lataa lasku" small class="mr-7 mt-4 pb-3" @click="downloadPdf(item._id)"
            >mdi-file-download</v-icon
          >

          <v-icon
            v-if="item.isLate && !item.paid && totalAmountWithTax(item) > 0"
            title="Lataa muistutuslasku"
            small
            class="mr-7 mt-4 pb-3"
            @click="downloadReminderPdf(item._id)"
            >mdi-bell</v-icon
          >
          <v-icon
            v-if="!item.paid"
            class="mr-7 mt-4 pb-3"
            title="Poista"
            small
            @click="deleteItem(item)"
            >mdi-delete</v-icon
          >
        </template>
        <template v-slot:no-data>
          <h2>Ei laskuja</h2>
        </template>
      </v-data-table>
      <v-btn @click="downloadMultiplePdfs" color="primary" dark class="mb-2 mt-5"
        >Lataa valitut</v-btn
      >
    </div>
  </v-container>
</template>

<script>
import axios from "axios";
import axiosMethods from "../../mixins/axios";
import mixins from "../../mixins/mixins";
import { mapActions, mapState, mapMutations } from "vuex";

export default {
  title: "Laskut",
  mixins: [mixins],
  data: () => ({
    loading: true,
    expired: false,
    options: {},
    dialog: false,
    active: "1",
    selectedInvoice: "",
    footerProps: {
      "items-per-page-options": [5, 10, 15, 20, 50, 100],
      showFirstLastPage: true,
      firstIcon: "mdi-arrow-collapse-left",
      lastIcon: "mdi-arrow-collapse-right",
      prevIcon: "mdi-minus",
      nextIcon: "mdi-plus",
    },
    selected: [],
    search: "",
    headers: [
      {
        text: "Laskun numero",
        value: "invoiceNumber",
      },
      { text: "Asiakas", value: "customer.name" },
      {
        text: "Summa sis. alv",
        value: "totalAmountWithTax",
      },
      {
        text: "Viitenumero.",
        value: "referenceNumber",
      },
      { text: "Laskun pvm.", value: "createdAt" },
      { text: "Eräpäivä", value: "dueDate" },
      { text: "Muistutettu", value: "lastReminder" },
      { text: "Lähetä @", value: "_id" },
      { text: "Lähetetty", value: "lastSend" },
      { text: "Maksettu", value: "paid" },
      {
        text: "Toiminnot",
        value: "actions",
        sortable: false,
      },
    ],
    partialPayments: [],
    partPayment: {
      id: null,
      invoiceNumber: null,
      price: null,
      itemTotalAmount: null,
      alreadyPaid: null,
      unPaid: null,
    },
  }),

  computed: {
    ...mapState("invoice", ["invoices", "totalLength"]),
    ...mapState("browsehistory", [
      "dataTablePage",
      "getPreviousPage",
      "itemsPerPage",
      "mainSearchValue",
    ]),
  },

  watch: {
    options: {
      handler() {
        if (this.getPreviousPage) {
          if (this.mainSearchValue != "") this.search = this.mainSearchValue;
          this.options.page = this.dataTablePage;
          this.options.itemsPerPage = this.itemsPerPage;
        }
        this.saveTableOptions({ ...this.options });
        this.getDataFromApi();
      },
      deep: true,
    },

    expired() {
      this.getDataFromApi();
    },

    totalLength() {
      this.options.page = 1;
    },
  },

  methods: {
    ...mapActions("invoice", ["getAllInvoices", "deleteInvoice"]),
    ...mapMutations("browsehistory", ["saveTableOptions", "saveMainSearchTerm"]),
    ...mapMutations("invoice", ["updateInvoices"]),

    async getDataFromApi() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;

      // Save main search term
      this.saveMainSearchTerm(this.search);

      let search = this.search.trim().toLowerCase();

      let url = this.expired
        ? `/api/v1/invoice/getAll?expired=true&sortBy=${sortBy}&sortDesc=${sortDesc}&page=${page}&itemsPerPage=${itemsPerPage}&search=${search}`
        : `/api/v1/invoice/getAll?sortBy=${sortBy}&sortDesc=${sortDesc}&page=${page}&itemsPerPage=${itemsPerPage}&search=${search}`;

      await this.getAllInvoices(url);

      this.loading = false;
    },

    totalAmountWithTax(item) {
      if (item.totalAmountWithTax) {
        return item.totalAmountWithTax;
      } else {
        return item.totalAmountVat24;
      }
    },

    editPartPayment(item) {
      let totalSum = 0;
      item.partialPayments.forEach((el) => {
        totalSum += el.payment;
      });
      this.partPayment.id = item._id;
      this.partPayment.invoiceNumber = item.invoiceNumber;
      this.partPayment.itemTotalAmount = this.formatPrice(this.totalAmountWithTax(item));
      this.partPayment.alreadyPaid = this.formatPrice(totalSum);
      this.partPayment.unPaid = this.formatPrice(this.totalAmountWithTax(item) - totalSum);
      this.partialPayments = item.partialPayments;
      this.selectedInvoice = item._id;
      this.dialog = true;
    },

    async savePartPayment() {
      let price = this.partPayment.price;
      if (price) {
        try {
          let res = await axiosMethods.updateOne(
            "/api/v1/invoice/updatePartPayment/" + this.partPayment.id,
            {
              price,
            }
          );

          this.updateInvoices(res.data.invoice);
          this.editPartPayment(res.data.invoice);
          this.showPopup("Osamaksun lisäys onnistui", "success");
        } catch (err) {
          this.showPopup(err, "error");
        }
      } else this.showPopup("Lisää ensin hinta", "error");
    },

    async deletePartPayment(paymentId) {
      try {
        const res = await axiosMethods.updateOne(
          "/api/v1/invoice/deletePartPayment/" + this.selectedInvoice,
          {
            paymentId,
          }
        );

        this.updateInvoices(res.data.invoice);
        this.editPartPayment(res.data.invoice);
        this.showPopup("Osamaksun poisto onnistui", "success");
      } catch (err) {
        this.showPopup(err, "error");
      }
    },

    editItem(item) {
      this.$router.push("/invoice/edit/" + item._id);
    },

    async deleteItem(item) {
      if (confirm("Haluatko varmasti poistaa laskun nro.: " + item.invoiceNumber)) {
        try {
          await this.deleteInvoice(item._id);
          this.showPopup("Lasku poistettu", "success");
        } catch (err) {
          this.showPopup(err, "error");
        }
      }
    },

    async markPayed(item) {
      if (confirm(`Haluatko muuttaa laskun nr. ${item.invoiceNumber} maksetuksi?`)) {
        try {
          await axiosMethods.updateOne("/api/v1/invoice/updateInvoicePaid/" + item._id);
          this.$router.go();
          this.showPopup("Laskun muokkaus onnistui", "success");
        } catch (err) {
          this.showPopup(err, "error");
        }
      }
    },
    // checkPaid(item) {
    //   return item.paid == 'Kyllä' ? false : true;
    // },
    // showSendBtn(item) {
    //   // If paid, hide
    //   if (item.paid === 'Kyllä') return false;
    //   // If not send, show
    //   if (item.lastSend === 'Ei lähetetty') return true;
    //   // If modified, show
    //   if (item.modified === true) return true;
    //   else return false;
    // },
    async sendPdf(item) {
      if (item.customer.email == "") {
        return this.showPopup("Lisää asiakkaalle ensin email!", "error");
      }
      if (
        confirm(
          `Haluatko lähettää laskun nro. ${item.invoiceNumber} asiakkaalle ${item.customer.name}, osoitteeseen ${item.customer.email}?`
        )
      ) {
        try {
          let res = await axiosMethods.get({
            method: "get",
            url: `/api/v1/invoice/sendInvoice/${item._id}`,
          });

          if (res.data.status == "success") {
            this.showPopup("Lasku lähetetty, odottaa varmistusta", "success");
          }
          this.$router.go();
        } catch (err) {
          this.showPopup(err, "error");
        }
      }
    },
    async downloadPdf(id) {
      try {
        axios.defaults.headers.common["Authorization"] = "Bearer " + this.$store.getters.getToken;

        const res = await axios({
          method: "get",
          url: `/api/v1/invoice/downloadPdf/${id}`,
          responseType: "blob",
        });

        let fileURL = URL.createObjectURL(res.data);
        window.open(fileURL);
      } catch (err) {
        let error = await axiosMethods.validateRequest(err);
        this.showPopup(error, "error");
      }
    },
    async downloadReminderPdf(id) {
      try {
        axios.defaults.headers.common["Authorization"] = "Bearer " + this.$store.getters.getToken;
        const res = await axios({
          method: "get",
          url: `/api/v1/invoice/downloadReminderPdf/${id}`,
          responseType: "blob",
        });

        let fileURL = URL.createObjectURL(res.data);

        window.open(fileURL);
      } catch (err) {
        let error = await axiosMethods.validateRequest(err);
        this.showPopup(error, "error");
      }
    },
    async downloadMultiplePdfs() {
      if (this.selected.length >= 1) {
        try {
          let ids = this.selected.map((item) => {
            return item._id;
          });

          axios.defaults.headers.common["Authorization"] = "Bearer " + this.$store.getters.getToken;
          const res = await axios({
            method: "post",
            url: `/api/v1/invoice/downloadMultiplePdfs/`,
            data: {
              ids,
            },
            responseType: "blob",
          });

          let fileURL = URL.createObjectURL(res.data);

          let link = document.createElement("a");
          link.href = fileURL;
          link.download = "Laskut.pdf";
          link.click();
          URL.revokeObjectURL(fileURL);
        } catch (err) {
          let error = await axiosMethods.validateRequest(err);
          this.showPopup(error, "error");
        }
      }
    },
    showReminderIcon(item) {
      if (item.paid) return true;
      return true;
    },
  },
};
</script>

<style scoped>
.isLate {
  color: red;
}

.partPayment {
  cursor: pointer;
  text-decoration-line: underline;
}

.popup-text {
  color: #1067b3;
  font-size: 15px;
}
</style>
