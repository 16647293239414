<template>
  <v-container>
    <h1 class="mt-5">Muokkaa laskutusprofiilia</h1>
    <v-form ref="form">
      <v-container>
        <v-row>
          <v-col cols="12" md="4">
            <v-radio-group v-model="profileData.isCompany" :mandatory="true">
              <v-radio label="Yksityinen" :value="false"></v-radio>
              <v-radio label="Yritys" :value="true"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field v-model="profileData.name" :rules="required" label="Nimi"></v-text-field>
          </v-col>

          <v-col v-if="profileData.isCompany" cols="12" md="4">
            <v-text-field v-model="profileData.businessId" :rules="required" label="Y-tunnus"></v-text-field>
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field v-model="profileData.email" :rules="required.concat(emailRules)" label="Email"></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="4">
            <v-text-field v-model="profileData.address" label="Osoite"></v-text-field>
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field v-model="profileData.zipCode" label="Postinumero"></v-text-field>
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field v-model="profileData.city" label="Kaupunki"></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="4">
            <v-text-field v-model="profileData.phone" label="Puhelin"></v-text-field>
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field v-model="profileData.fax" label="Faksi"></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="4">
            <v-text-field v-model="profileData.bank" :rules="required" label="Pankki"></v-text-field>
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field v-model="profileData.iban" :rules="required" label="Tilinumero"></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="4">
            <v-text-field v-model="profileData.paymentTerms" label="Maksuehdot"></v-text-field>
          </v-col>

          <v-col cols="12" md="3">
            <v-text-field
              type="number"
              value="timeDiscount1"
              v-model.number="profileData.timeDiscount1"
              :rules="positiveNumber"
              label="Kassa-alennus 7 päivää (%)"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="3">
            <v-text-field
              type="number"
              value="timeDiscount2"
              v-model.number="profileData.timeDiscount2"
              :rules="positiveNumber"
              label="Kassa-alennus 14 päivää (%)"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="3">
            <v-text-field
              type="number"
              value="penaltyInterest"
              v-model.number="profileData.penaltyInterest"
              :rules="positiveNumber"
              label="Viivästyskorko"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="3">
            <v-text-field
              type="number"
              value="reminderPayment"
              v-model.number="profileData.reminderPayment"
              :rules="positiveNumber"
              label="Muistutusmaksu (€)"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="3">
            <v-text-field type="number" v-model.number="profileData.reminderInterval" :rules="positiveNumber" label="Huomautusaika (päivää)"></v-text-field>
          </v-col>
        </v-row>
        <v-btn color="success" class="mr-4" @click="submit">Tallenna</v-btn>
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
import axiosMethods from '../../mixins/axios';
import mixins from '../../mixins/mixins';

export default {
  title: 'Muokkaa profiilia',
  mixins: [mixins],
  data(vm) {
    return {
      profileData: {},
      emailRules: [(v) => /.+@.+\..+/.test(v) || 'Email ei ole oikeassa muodossa'],
      required: [(v) => !!v || 'Pakollinen kenttä'],
      positiveNumber: [(v) => vm.checkNumber(v) || 'Anna positiivinen luku'],
    };
  },
  async created() {
    let res = await axiosMethods.get('/api/v1/profile/getOne/' + this.$route.params.id);
    let profileData = res.data.data;

    let keys = Object.keys(profileData);

    keys.forEach((key) => {
      if (profileData[key] == null) {
        profileData[key] = '';
      }
    });

    this.profileData = profileData;
  },
  methods: {
    checkNumber(v) {
      if (v > 0 || v == '') return true;
      else return false;
    },
    async submit() {
      if (this.$refs.form.validate()) {
        try {
          await axiosMethods.updateOne('/api/v1/profile/update/' + this.$route.params.id, this.profileData);
          this.showPopup('Profiili tallennettu', 'success');
        } catch (err) {
          this.showPopup('Profiilin tallentaminen epäonnistui' + err, 'error');
        }
      }
    },
  },
};
</script>

<style></style>
