var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"mt-5"},[_c('v-row',{staticClass:"justify-center"},[_c('v-col',{attrs:{"md":"4"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Etsi","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{attrs:{"show-select":"","multi-sort":"","search":_vm.search,"headers":_vm.headers,"items":_vm.invoices},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Laskuni")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer')],1)]},proxy:true},{key:"item.totalAmountWithTax",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatPrice(_vm.totalAmountWithTax(item))))])]}},{key:"item.dueDate",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ isLate: item.isLate && item.paid === 'Ei' }},[_vm._v(_vm._s(item.dueDate))])]}},{key:"item.paid",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.paid))])]}},{key:"item.lastSend",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.lastSend))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-5",attrs:{"title":"Lataa lasku","small":""},on:{"click":function($event){return _vm.downloadPdf(item)}}},[_vm._v("mdi-file-pdf")]),(item.isLate && item.paid === 'Ei')?_c('v-icon',{staticClass:"mr-5",attrs:{"title":"Lataa muistutuslasku","small":""},on:{"click":function($event){return _vm.downloadReminderPdf(item)}}},[_vm._v("mdi-bell")]):_vm._e()]}},{key:"no-data",fn:function(){return [_c('h2',[_vm._v("Ei laskuja")])]},proxy:true}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('v-btn',{staticClass:"mb-2 mt-5",attrs:{"color":"primary","dark":""},on:{"click":_vm.downloadMultiplePdfs}},[_vm._v("Lataa valitut")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }