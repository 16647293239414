<script>
import { Bar } from 'vue-chartjs';

export default {
  extends: Bar,
  props: ['options', 'monthlyData', 'title'],
  data() {
    return {};
  },
  mounted() {
    // Overwriting base render method with actual data.
    this.renderChart(
      {
        labels: [
          'Tammikuu',
          'Helmikuu',
          'Maaliskuu',
          'Huhtikuu',
          'Toukokuu',
          'Kesäkuu',
          'Heinäkuu',
          'Elokuu',
          'Syyskuu',
          'Lokakuu',
          'Marraskuu',
          'Joulukuu',
        ],
        datasets: [
          {
            label: this.title,
            backgroundColor: '#f87979',
            data: this.monthlyData,
          },
        ],
      },
      this.options
    );
  },
};
</script>

<style></style>
