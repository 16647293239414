import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import titleMixin from './mixins/titleMixins';

Vue.mixin(titleMixin);

window.addEventListener("popstate", () => {
    store.state.browsehistory.getPreviousPage = true;
});

Vue.config.productionTip = false;

// Global eventbus
export const eventBus = new Vue();

// Eventbus for snackbar toasts
export const snacbarBus = new Vue();

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
