<template>
  <v-app>
    <MainMenu></MainMenu>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import MainMenu from "../src/components/MainMenu";
import mixins from './mixins/mixins'

export default {
  name: "App",
  components: { MainMenu },
  mixins: [mixins],
  data: () => ({
    //
  }),

};
</script>
