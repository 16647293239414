<template>
  <v-container>
    <v-form ref="form1">
      <v-container>
        <v-row class="justify-center">
          <v-col cols="10" md="7" lg="5" xl="4">
            <h1 class="mt-5 mb-5">Rekisteröidy tai hyväksy liittymispyyntö</h1>
            <p>
              Jos et ole vielä järjestelmän käyttäjä, ole hyvä ja rekisteröidy.
              <br />
              <br />Jos olet jo rekisteröitynyt käyttäjä, hyväksy laskuttajan liittämispyyntö rekisteröitymislomakkeen alta syöttämällä kirjautumistunnuksesi ja painamalla hyväksy pyyntö.
            </p>
            <h2 class="mt-5 mb-5">Rekisteröidy</h2>
            <v-text-field v-model="data.name" :rules="required" label="Nimi"></v-text-field>
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col cols="10" md="7" lg="5" xl="4">
            <v-text-field v-model="data.email" :rules="required.concat(emailRules)" label="Email"></v-text-field>
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col cols="10" md="7" lg="5" xl="4">
            <v-text-field
              v-model="data.password"
              :rules="required"
              type="password"
              label="Salasana"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col cols="10" md="7" lg="5" xl="4">
            <v-text-field
              v-model="data.passwordConfirm"
              :rules="required"
              type="password"
              label="Toista salasana"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col cols="10" md="7" lg="5" xl="4">
            <v-btn color="success" class="mr-4" @click.prevent="register">Rekisteröidy</v-btn>
          </v-col>
        </v-row>

        <br />
        <hr />

        <!--  Accept Request -->
        <v-form ref="form2">
          <v-container>
            <v-row class="justify-center">
              <v-col cols="10" md="7" lg="5" xl="4">
                <h2 class="mt-5 mb-5">Hyväksy liittämispyyntö</h2>
                <v-text-field v-model="credentials.email" label="Käyttäjätunnus"></v-text-field>
              </v-col>
            </v-row>

            <v-row class="justify-center">
              <v-col cols="10" md="7" lg="5" xl="4">
                <v-text-field v-model="credentials.password" type="password" label="Salasana"></v-text-field>
                <router-link to="/forgotpassword">
                  <small>Unohditko salasanasi?</small>
                </router-link>
              </v-col>
            </v-row>

            <v-row class="justify-center">
              <v-col cols="10" md="7" lg="5" xl="4">
                <v-btn color="success" class="mr-4" @click.prevent="accept">Hyväksy pyyntö</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
import mixins from "../../mixins/mixins";
import axiosMethods from "../../mixins/axios";

export default {
  title: "Rekisteröidy tai vahvista",
  mixins: [mixins],
  data() {
    return {
      data: {
        email: "",
        password: "",
        passwordConfirm: ""
      },
      credentials: {
        email: "",
        password: ""
      },
      emailRules: [
        v => /.+@.+\..+/.test(v) || "Email ei ole oikeassa muodossa"
      ],
      required: [v => !!v || "Pakollinen kenttä"]
    };
  },
  methods: {
    async register() {
      if (this.$refs.form1.validate()) {
        try {
          await axiosMethods.createOne(
            "/api/v1/user/register/" + this.$route.params.merchantId,
            this.data
          );
          this.$router.push("/login");
          this.showPopup(
            "Rekisteröityminen onnistui, voit kirjatua sisään",
            "success"
          );
        } catch (err) {
          this.showPopup(err, "error");
        }
      }
    },
    async accept() {
      if (this.$refs.form2.validate()) {
        try {
          await axiosMethods.createOne(
            "/api/v1/user/addToMerchant/" + this.$route.params.merchantId,
            this.credentials
          );
          this.$router.push("/login");
          this.showPopup(
            "Yritys on lisätty käyttäjällesi, voit kirjatua sisään",
            "success"
          );
        } catch (err) {
          this.showPopup(err, "error");
        }
      }
    }
  }
};
</script>

<style></style>
