<template>
  <v-card tile class="mx-auto">
    <!-- Invoices -->

    <v-list
      v-if="title == 'LASKUT'"
      :disabled="disabled"
      :dense="dense"
      :two-line="twoLine"
      :three-line="threeLine"
      :shaped="shaped"
      :flat="flat"
      :subheader="subheader"
      :sub-group="subGroup"
      :nav="nav"
      :avatar="avatar"
      :rounded="rounded"
    >
      <v-subheader class="ml-5">{{ title }}</v-subheader>
      <v-list-item-group color="primary">
        <v-list-item v-for="(item, index) in invoiceStats" :key="index" :inactive="inactive">
          <v-list-item-content class="ml-5 content-wrapper">
            <div v-for="(profile, i) in item" :key="i">
              <v-list-item-title class="mb-1 ">
                <span style="font-weight: 500">{{ profile.name }}</span> {{ profile.title }}
              </v-list-item-title>
              <v-list-item-subtitle class="subtitle"
                >{{ profile.count }} <span v-if="profile.count">laskua /</span> {{ formatPrice(profile.totalAmount) }}</v-list-item-subtitle
              >
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>

    <!-- Products -->

    <v-list
      v-if="title == 'TUOTTEET / PALVELUT'"
      :disabled="disabled"
      :dense="dense"
      :two-line="twoLine"
      :three-line="threeLine"
      :shaped="shaped"
      :flat="flat"
      :subheader="subheader"
      :sub-group="subGroup"
      :nav="nav"
      :avatar="avatar"
      :rounded="rounded"
    >
      <v-subheader class="ml-5">{{ title }}</v-subheader>
      <v-list-item-title style="font-weight: 500" class="ml-9 mt-2">{{ top10Products }} (alv 0)</v-list-item-title>
      <v-list-item-group>
        <v-list-item v-for="(item, index) in productStats" :key="index" :inactive="inactive">
          <v-list-item-content class="ml-6 content-wrapper">
            <v-list-item-subtitle class="top-list-item">{{ item.name }}</v-list-item-subtitle>
            <v-list-item-subtitle class="top-list-subitem">{{ formatPrice(item.totalAmount) }} | {{ item.count }} kpl</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-card>
</template>

<script>
import mixins from '../../../mixins/mixins';

export default {
  props: ['invoiceStats', 'productStats', 'top10Products', 'title'],
  mixins: [mixins],
  data() {
    return {
      disabled: false,
      dense: false,
      twoLine: false,
      threeLine: false,
      shaped: false,
      flat: true,
      subheader: true,
      inactive: false,
      subGroup: false,
      nav: true,
      avatar: false,
      rounded: false,
    };
  },
};
</script>

<style scoped>
.subtitle {
  color: rgb(134, 134, 134) !important;
  font-size: 15px;
}

.v-list-top {
  margin-top: -15px;
}

.top-list-item {
  font-size: 0.95em !important;
  color: rgb(70, 70, 70) !important;
  font-size: 12px;
  margin-top: -4px;
}

.top-list-subitem {
  font-size: 0.9em;
  color: rgb(90, 90, 90) !important;
}

.content-wrapper {
  border-bottom: 1px solid rgb(218, 217, 217);
  margin-bottom: 5px;
  padding-left: 5px;
}
</style>
