var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"mt-5"},[_c('v-row',{staticClass:"justify-center"},[_c('v-col',{attrs:{"md":"4"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Etsi","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{attrs:{"multi-sort":"","search":_vm.search,"headers":_vm.headers,"items":_vm.orders,"show-expand":"","single-expand":_vm.singleExpand},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Tilaukset")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer')],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pb-3 pt-3",attrs:{"colspan":headers.length}},_vm._l((item.products),function(product,index){return _c('div',{key:index},[_c('strong',{staticStyle:{"margin-bottom":"-3px"}},[_vm._v(" "+_vm._s(index + 1)+". "),_c('span',{staticStyle:{"margin-bottom":"-3px"}},[_vm._v(_vm._s(product.name))])]),_c('p',{staticStyle:{"margin-bottom":"-3px"}},[_vm._v(" Asiakkaan hinta: "),_c('span',{staticStyle:{"font-weight":"600"}},[_vm._v(_vm._s(_vm.formatPrice(product.price)))])]),_c('p',{staticStyle:{"margin-bottom":"-3px"}},[_vm._v(" Määrä: "),_c('span',{staticStyle:{"font-weight":"600"}},[_vm._v(_vm._s(product.quantity)+" kpl")])]),_c('small',[_vm._v(_vm._s(product.details))])])}),0)]}},{key:"item.dueDate",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ isLate: item.isLate && item.paid === 'Ei' }},[_vm._v(_vm._s(item.dueDate))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-5",attrs:{"title":"Poista","small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")])]}},{key:"no-data",fn:function(){return [_c('h2',[_vm._v("Ei tilauksia")])]},proxy:true}],null,true)})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }