<template>
  <v-container>
    <h1 class="mt-5">Luo uusi ryhmä</h1>
    <v-form ref="form">
      <v-row>
        <v-col cols="12" md="4">
          <v-text-field v-model="name" :rules="required" label="Ryhmän nimi"></v-text-field>
        </v-col>
      </v-row>
    </v-form>

    <h2 class="mt-3">Jäsenet</h2>
    <v-row>
      <v-col>
        <div class="customer-wrapper">
          <v-treeview v-model="selected" :items="customers" selection-type="independent" selectable return-object open-all></v-treeview>
        </div>
      </v-col>
      <v-divider vertical></v-divider>
      <v-col class="pa-6" cols="6">
        <div class="customer-list-wrapper">
          <!--  No customers -->
          <template v-if="!selected.length">Ei valittuja asiakkaita</template>
          <!-- Selected customers -->
          <template v-else>
            <div v-for="customer in selected" :key="customer.id">{{ customer.name }}</div>
          </template>
        </div>
      </v-col>
    </v-row>

    <v-btn class="mt-5" color="success" @click="submit">Luo ryhmä</v-btn>
  </v-container>
</template>

<script>
import mixins from '../../mixins/mixins';
import axiosMethods from '../../mixins/axios';

export default {
  title: 'Uusi ryhmä',
  mixins: [mixins],
  data() {
    return {
      selected: [],
      name: '',
      customers: [],
      required: [(v) => !!v || 'Pakollinen kenttä'],
    };
  },
  async created() {
    let res = await axiosMethods.get('/api/v1/customer/getAll');
    this.customers = res.data.data;
  },
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        try {
          // get ids
          let ids = this.selected.map((el) => {
            return el._id;
          });
          // Create data object
          let data = {
            name: this.name,
            ids,
          };
          // Request
          await axiosMethods.createOne('/api/v1/group/create', data);

          this.$router.push('/groups');
          this.showPopup('Uusi ryhmä luotu', 'success');
        } catch (err) {
          this.showPopup(err, 'error');
        }
        //  reset
        // this.$refs.form.reset();
        // }
      }
    },
  },
};
</script>

<style scoped>
.customer-wrapper {
  margin-left: -35px;
  max-height: 500px;
  overflow: auto;
}
.customer-list-wrapper {
  max-height: 500px;
  overflow: auto;
}
</style>
