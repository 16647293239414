<template>
  <v-container>
    <div class="mt-5">
      <v-row class="justify-center">
        <v-col md="4">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Etsi"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <v-data-table :headers="headers" :items="products" :search="search">
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Tuotteet / Palvelut</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
          </v-toolbar>
        </template>

        <!--  Price -->
        <template v-slot:[`item.dueDate`]="{ item }">
          <span>{{ item.price }}</span>
        </template>

        <!-- Actions -->
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon color="green" title="Lisää koriin" medium class="mr-5" @click="addToCart(item)"
            >mdi-cart</v-icon
          >
        </template>

        <template v-slot:no-data>
          <h2>Ei tuotteita tai palveluita tarjolla tällä hetkellä</h2>
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>

<script>
import axiosMethods from "../../../mixins/axios";
import mixins from "../../../mixins/mixins";
import { eventBus } from "../../../main";

export default {
  title: "Tuotteet",
  mixins: [mixins],
  data: () => ({
    cart: [],
    search: "",
    headers: [
      {
        text: "Koodi",
        value: "productId",
      },
      { text: "Nimi", value: "name" },
      { text: "Listahinta (alv 0%)", value: "price" },
      {
        text: "Kauppias",
        value: "createdBy.name",
      },
      { text: "Lisää koriin", value: "actions", sortable: false },
    ],
    products: [],
  }),
  async created() {
    try {
      eventBus.$on("setCartEmpty", () => {
        this.cart = [];
      });

      let res = await axiosMethods.get("/api/v1/product/getAll");
      let products = res.data.products;

      products = products.map((el) => {
        el.price = this.formatPrice(el.price);
        return el;
      });
      this.products = products;
    } catch (err) {
      this.showPopup(err, "error");
    }
  },
  methods: {
    addToCart(item) {
      let product = {
        ...item,
        quantity: 1,
        details: "",
      };
      this.$store.commit("addToCart", product);
      eventBus.$emit("showCart", true);
    },
  },
};
</script>

<style></style>
