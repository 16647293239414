import { snacbarBus } from '../main';
import moment from 'moment';


export default {
  methods: {
    formatPrice: (price) => {
      if (price > 0 || price < 0) {
        return price.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') + ' €';
      } else return ' - €';
    },

    formatDate(date) {
      if (date) return moment(date).format('DD.MM.YYYY');
      else return '';
    },

    formatDateInvoices(date, state) {
      if (state == 'pending') {
        return 'Odottaa varmistusta';
      }
      if (state == 'fail') {
        return 'Lähetyksessä ilmeni virhe';
      }
      if (date && state == 'sent') return moment(date).format('DD.MM.YYYY');
      else return 'Ei lähetetty';
    },

    formatBoolean(boolean) {
      return boolean == false ? 'Ei' : 'Kyllä';
    },

    formatIsCompany(boolean) {
      return boolean == false ? 'Yksityinen' : 'Yritys';
    },

    showPopup: (text, color) => {
      snacbarBus.$emit('createSnackbar', {
        text: text,
        color: color,
      });
    },

 
  },
};
