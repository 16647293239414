<template>
  <v-container>
    <div class="mt-5">
      <v-row class="justify-center">
        <v-col md="4">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Etsi"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>

      <v-data-table
        :search="search"
        :headers="headers"
        :items="customers"
        v-model="selected"
        show-select
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Asiakkaat</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <router-link to="/customer/create">
              <v-btn color="primary" dark class="mb-2">Uusi asiakas</v-btn>
            </router-link>
          </v-toolbar>
        </template>

        <!-- Name-->
        <template v-slot:[`item.name`]="{ item }">
          <span style="color: #42a5f5; cursor: pointer" @click="showInfo(item)">{{
            item.name
          }}</span>
        </template>

        <!-- Merchant Request Send-->
        <template v-slot:[`item.merchantRequestSend`]="{ item }">
          <span>{{ formatMessageSend(item.merchantRequestSend) }}</span>
        </template>
        <!-- Merchant Request Accepted-->
        <template v-slot:[`item.merchantRequestAccepted`]="{ item }">
          <span>{{ formatMessageSend(item.merchantRequestAccepted) }}</span>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-5" @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
        </template>

        <template v-slot:no-data>
          <h2>Ei asiakkaita</h2>
        </template>
      </v-data-table>

      <!-- Dialog -->

      <v-dialog v-model="dialog" max-width="500">
        <customer-statistics
          @closeDialog="dialog = false"
          :customer="customer"
        ></customer-statistics>
      </v-dialog>

      <v-btn @click="createMultipleCustomers()" color="primary" dark class="mb-2 mt-5"
        >Lähetä pyyntö valituille</v-btn
      >
      <br />
      <small
        >Asiakas saa sähköpostin, jossa häntä pyydetään rekisteröitymään palveluun ja/tai
        hyväksymään lähettämäsi pyyntö.</small
      >
    </div>
  </v-container>
</template>

<script>
import axiosMethods from "../../mixins/axios";
import mixins from "../../mixins/mixins";
import CustomerStatistics from "../../components/CustomerStatistics";

export default {
  title: "Asiakkaat",
  components: { CustomerStatistics },
  mixins: [mixins],
  data: () => ({
    dialog: false,
    customer: {},
    selected: [],
    search: "",
    headers: [
      {
        text: "Nimi",
        value: "name",
      },
      { text: "Tyyppi", value: "isCompany" },
      { text: "Y-tunnus", value: "businessId" },
      { text: "Email", value: "email" },
      { text: "Pyyntö lähetetty", value: "merchantRequestSend" },
      { text: "Pyyntö hyväksytty", value: "merchantRequestAccepted" },
      { text: "Toiminnot", value: "actions", sortable: false },
    ],
    customers: [],
  }),
  async created() {
    try {
      let res = await axiosMethods.get("/api/v1/customer/getAll");
      let customers = res.data.data;

      customers = customers.map((el) => {
        el.isCompany = this.formatIsCompany(el.isCompany);
        return el;
      });

      this.customers = customers;
    } catch (err) {
      this.showPopup(err, "error");
    }
  },
  methods: {
    editItem(item) {
      this.$router.push("/customer/edit/" + item._id);
    },
    async deleteItem(item) {
      if (
        confirm(
          "HUOM! Mikäli poistat asiakasyrityksen, yritys ei enää pääse tarkastelemaan tuotteitasi tai näkemään laskujasi. Haluatko varmasti poistaa asiakkaan " +
            item.name +
            "?"
        )
      ) {
        try {
          await axiosMethods.delete("/api/v1/customer/delete/" + item._id);
          this.showPopup("Asiakas poistettu", "success");
          this.$router.go();
        } catch (err) {
          this.showPopup(err, "error");
        }
      }
    },
    async createMultipleCustomers() {
      if (confirm("Haluatko lähettää tunnukset valituille asiakkaille?")) {
        if (this.selected.length >= 1) {
          try {
            let ids = this.selected.map((item) => {
              return item._id;
            });

            await axiosMethods.createOne(`api/v1/user/createUserAndSendEmail/`, ids);

            this.$router.go();
            this.showPopup("Rekisteröitymispyynnöt lähetetty asiakkaille", "success");
          } catch (err) {
            let error = await axiosMethods.validateRequest(err);
            this.showPopup(error, "error");
          }
        }
      }
    },
    formatMessageSend(item) {
      return item == true ? "Kyllä" : "Ei";
    },
    async showInfo(item) {
      try {
        let res = await axiosMethods.get("/api/v1/statistics/getSingleCustomer/" + item._id);

        this.customer = res.data.customerStats;
        this.dialog = true;
      } catch (err) {
        this.showPopup("Jokin meni pieleen", "error");
      }
    },
  },
};
</script>

<style></style>
