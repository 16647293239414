import invoiceService from '../../services/invoice.service'

const state = {
    invoices: [],
    totalLength: 0
}

const mutations = {
 
  addAllInvoices(state, data) {
    state.invoices = data.invoices;
    state.totalLength = data.totalLength;
  },

  updateInvoices(state, invoice) {
    const index = state.invoices.findIndex(el => el._id == invoice._id)

    if(index != -1) {
      state.invoices.splice(index, 1, invoice)
    }
  },

  deleteInvoice(state, id) {
   state.invoices = state.invoices.filter((el) => el._id != id);
  }
}

const actions = {
  async getAllInvoices({ commit }, url) {
    try {
      const res = await invoiceService.getAllInvoices(url);
      commit("addAllInvoices", {
        invoices: res.data.invoices,
        totalLength: res.data.totalLength,
      });
    } catch (err) {
      throw new Error(err)
    }
  },

  async deleteInvoice({ commit }, id) {
    try {
      await invoiceService.deleteInvoice(id);
      commit("deleteInvoice", id); 
    } catch (err) {
      throw new Error(err)
    }
  },
}

export default {
  state,
  actions,
  mutations,
  namespaced: true,
};