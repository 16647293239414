<template>
  <v-container class="mt-5">
    <v-row justify="center">
      <v-col lg="4" md="6" cols="12">
        <customer-card class="card" :invoiceStats="invoiceStats" title="LASKUT"></customer-card>
      </v-col>
      <v-col lg="4" md="6" cols="12">
        <customer-card class="card" top10Products="Top 10" :productStats="productStats" title="TUOTTEET / PALVELUT"></customer-card>
      </v-col>
    </v-row>
    <!-- <v-row>
      <v-col class="mt-5" v-if="monthlyLoaded">
        <h2>Kuukausittaiset ostot vuonna {{ thisYear }}</h2>
        <MonthlySells
          :monthlyData="monthlyData"
          :options="options"
          :title="`Kokonaisostot vuonna ${new Date().getFullYear()} kaikilta yrityksiltä`"
        ></MonthlySells>
      </v-col>
    </v-row>-->
  </v-container>
</template>

<script>
import axiosMethods from '../../../mixins/axios';
import mixins from '../../../mixins/mixins';
import CustomerCard from '../../../components/charts/UserCharts/UserCard';

export default {
  title: 'Tilastot',
  mixins: [mixins],
  components: { CustomerCard },
  data() {
    return {
      dailyLoaded: false,
      monthlyLoaded: false,
      invoiceStats: [],
      productStats: [],
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                callback: function(value) {
                  return value + ' €';
                },
              },
            },
          ],
        },
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          backgroundColor: 'rgb(255,255,255)',
          bodyFontColor: '#858796',
          titleMarginBottom: 10,
          titleFontColor: '#6e707e',
          titleFontSize: 14,
          borderColor: '#dddfeb',
          borderWidth: 1,
          xPadding: 15,
          yPadding: 15,
          displayColors: false,
          intersect: false,
          mode: 'index',
          caretPadding: 10,
          callbacks: {
            label: function(tooltipItem) {
              return tooltipItem.yLabel + ' €';
            },
          },
        },
      },
    };
  },
  async created() {
    try {
      let res1 = await axiosMethods.get('/api/v1/statistics/getUserInvoiceData');
      this.createInvoiceStats(res1.data.invoiceStats[0]);
      this.createProductStats(res1.data.invoiceStats[0]);
    } catch (err) {
      this.showPopup(err, 'error');
    }
  },
  computed: {
    thisYear() {
      return new Date().getFullYear();
    },
  },
  methods: {
    createInvoiceStats(item) {
      let { allInvoices, purchasesThisYear, averagePurchase } = item;

      let allInvoiceData = [];

      if (allInvoices.length > 0) {
        let data = [];

        allInvoices.forEach((el) => {
          data.push({
            name: el._id,
            title: `kokonaisostot`,
            totalAmount: el.totalAmount,
            count: el.count,
          });
        });

        allInvoiceData.push(data);
      } else {
        allInvoiceData.push([
          {
            title: 'Kokonaisostot',
            totalAmount: 0,
          },
        ]);
      }

      // Purchase data
      if (purchasesThisYear.length > 0) {
        let data = [];

        allInvoices.forEach((el) => {
          data.push({
            name: el._id,
            title: `ostot vuonna ${new Date().getFullYear()}`,
            totalAmount: el.totalAmount,
            count: el.count,
          });
        });
        allInvoiceData.push(data);
      } else
        allInvoiceData.push([
          {
            title: `Ostot vuonna ${new Date().getFullYear()}`,
            totalAmount: 0,
          },
        ]);

      if (averagePurchase.length > 0) {
        let data = [];

        averagePurchase.forEach((el) => {
          data.push({
            name: el._id,
            title: `keskiostos`,
            totalAmount: el.avgAmount,
          });
        });
        allInvoiceData.push(data);
      } else
        allInvoiceData.push([
          {
            title: 'Keskiostos',
            totalAmount: 0,
          },
        ]);

      // Assing to variables
      this.invoiceStats = allInvoiceData;
    },
    createProductStats(item) {
      let { top10Products } = item;

      let all = [];

      if (top10Products.length > 0) {
        top10Products.forEach((el) => {
          let data = {
            name: `Id: ${el._id.productId}`,
            totalAmount: el.totalAmount,
            count: el.count,
          };
          all.push(data);
        });
      }
      this.productStats = all;
    },
  },
};
</script>

<style scoped>
.card {
  height: 100%;
}
</style>
