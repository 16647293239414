<template>
  <v-container class="mt-5">
    <v-card class="mt-2">
      <v-card-title>Laskutusraportti</v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="reportForm">
            <v-row dense>
              <v-col cols="12" sm="6" md="3" lg="3" xl="2">
                <v-menu
                  ref="startMenu"
                  v-model="startMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  max-width="290px"
                  min-width="290px"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      v-model="startDateFormatted"
                      outlined
                      dense
                      label="Alkaen"
                      v-bind="attrs"
                      :rules="required"
                      v-on="on"
                      append-icon="mdi-calendar"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dates.startDate"
                    first-day-of-week="1"
                    @input="startMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" md="3" lg="3" xl="2">
                <v-menu
                  ref="endMenu"
                  v-model="endMenu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  max-width="290px"
                  min-width="290px"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      v-model="endDateFormatted"
                      outlined
                      dense
                      label="Päättyen"
                      v-bind="attrs"
                      :rules="required"
                      v-on="on"
                      append-icon="mdi-calendar"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dates.endDate"
                    first-day-of-week="1"
                    @input="endMenu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-form>

          <v-row dense>
            <v-col md="4">
              <v-btn color="info" @click="getInvoiceReport">Hae laskutustiedot</v-btn>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col>
              <div v-if="showInvoiceData" class="mt-3 invoice-data">
                <p>Laskuja yhteensä: {{ invoiceData.count }} kpl</p>
                <p>Kokonaismyynti: {{ formatPrice(invoiceData.totalAmount) }} alv 0%</p>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
    <v-row>
      <v-col lg="4" md="6" cols="12">
        <Card class="card" title="LASKUTUS" :items="invoiceStats"></Card>
      </v-col>
      <v-col lg="4" md="6" cols="12">
        <Card class="card" title="KASSAVIRTA" :items="cashFlowStats"></Card>
      </v-col>
      <v-col lg="4" md="6" cols="12">
        <Card class="card" title="MYYNTI" :items="sellingStats"></Card>
      </v-col>
      <v-col lg="4" md="6" cols="12">
        <Card
          class="card"
          title="ASIAKKAAT"
          top10Customers="Top 10 asiakasta"
          :customers="customerSells"
          :items="customerStats"
        ></Card>
      </v-col>
      <v-col lg="4" md="6" cols="12">
        <Card
          class="card"
          title="TUOTTEET"
          top10Products="Top 10 tuotteet"
          :products="productSells"
          :items="productStats"
        ></Card>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="mt-5" v-if="dailyLoaded">
        <h2>Kuluvan kuukauden päivittäinen myynti</h2>
        <DailySells
          :dailyData="dailyData"
          :options="options"
          :thisMonthsDaysArr="thisMonthsDaysArr"
        ></DailySells>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="mt-5" v-if="monthlyLoaded">
        <h2>Kuukausittainen myynti vuonna {{ thisYear }}</h2>
        <MonthlySells
          :monthlyData="monthlyData"
          :options="options"
          :title="`Kuukausittainen myynti vuonna ${new Date().getFullYear()}`"
        ></MonthlySells>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="mt-5" v-if="yearlyLoaded">
        <h2>Vuosittaiset myynnit</h2>
        <YearlySells
          :yearlyData="yearlyData"
          :yearLabels="yearLabels"
          :options="options"
          title="Vuosittaiset myynnit"
        ></YearlySells>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Card from "../../components/charts/Card";
import axiosMethods from "../../mixins/axios";
import mixins from "../../mixins/mixins";
import DailySells from "../../components/charts/DailySells";
import MonthlySells from "../../components/charts/MonthlySells";
import YearlySells from "../../components/charts/YearlySells";

export default {
  title: "Tilastot",
  mixins: [mixins],
  components: {
    Card,
    DailySells,
    MonthlySells,
    YearlySells,
  },
  data() {
    return {
      dates: {
        startDate: "",
        endDate: "",
      },
      showInvoiceData: false,
      invoiceData: { count: 0, totalAmount: 0 },
      startMenu: false,
      endMenu: false,
      startDateFormatted: "",
      endDateFormatted: "",
      customerStats: [],
      invoiceStats: [],
      cashFlowStats: [],
      sellingStats: [],
      productStats: [],
      productSells: [],
      customerSells: [],
      thisMonthsDaysArr: [],
      dailyData: [],
      monthlyData: [],
      yearlyData: [],
      yearLabels: [],
      dailyLoaded: false,
      monthlyLoaded: false,
      yearlyLoaded: false,
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                callback: function (value) {
                  return value + " €";
                },
              },
            },
          ],
        },
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          backgroundColor: "rgb(255,255,255)",
          bodyFontColor: "#858796",
          titleMarginBottom: 10,
          titleFontColor: "#6e707e",
          titleFontSize: 14,
          borderColor: "#dddfeb",
          borderWidth: 1,
          xPadding: 15,
          yPadding: 15,
          displayColors: false,
          intersect: false,
          mode: "index",
          caretPadding: 10,
          callbacks: {
            label: function (tooltipItem) {
              return tooltipItem.yLabel + " €";
            },
          },
        },
      },
      required: [(v) => !!v || "Pakollinen kenttä"],
    };
  },
  async created() {
    try {
      let res1 = await axiosMethods.get("/api/v1/statistics/getInvoiceData");
      let res2 = await axiosMethods.get("/api/v1/statistics/getCustomerData");
      let res3 = await axiosMethods.get("/api/v1/statistics/getProductData");
      let res4 = await axiosMethods.get("/api/v1/statistics/cashFlowStats");

      this.createInvoiceStats(res1.data.invoiceStats[0]);
      this.createDailyStats(res1.data.invoiceStats[0]);
      this.createMonthlyStats(res1.data.invoiceStats[0]);
      this.createYearlyStats(res1.data.invoiceStats[0]);
      this.createCustomerStats(res2.data.customerStats[0]);
      this.createCustomerSells(res2.data.customerSells[0]);
      this.createProductStats(res3.data.productStats[0]);
      this.createProductSells(res1.data.invoiceStats[0]);
      this.createCashFlowStats(res4.data.cashFlowStats[0]);
    } catch (err) {
      this.showPopup(err, "error");
    }
  },
  computed: {
    thisYear() {
      return new Date().getFullYear();
    },
  },

  watch: {
    "dates.startDate"() {
      this.startDateFormatted = this.formatDate(this.dates.startDate);
    },
    "dates.endDate"() {
      this.endDateFormatted = this.formatDate(this.dates.endDate);
    },
  },
  methods: {
    createInvoiceStats(item) {
      let {
        allInvoices,
        isLateInvoices,
        paidInvoices,
        unPaidInvoices,
        sellsThisYear,
        sellsThisMonth,
        sellsToday,
        averageSell,
      } = item;

      let allInvoiceData = [];
      let allSellingData = [];

      if (allInvoices.length > 0) {
        let data = {
          title: "Laskuja yhteensä",
          totalAmount: allInvoices[0].totalAmount,
          count: allInvoices[0].count,
        };
        allInvoiceData.push(data);
      } else {
        allInvoiceData.push({
          title: "Laskuja yhteensä",
          totalAmount: 0,
          count: 0,
        });
      }
      if (isLateInvoices.length > 0) {
        let data = {
          title: "Myöhässä olevat laskut",
          totalAmount: isLateInvoices[0].totalAmount,
          count: isLateInvoices[0].count,
        };
        allInvoiceData.push(data);
      } else
        allInvoiceData.push({
          title: "Myöhässä olevat laskut",
          totalAmount: 0,
          count: 0,
        });
      if (paidInvoices.length > 0) {
        let data = {
          title: "Maksusuoritukset",
          totalAmount: paidInvoices[0].totalAmount,
          count: paidInvoices[0].count,
        };
        allInvoiceData.push(data);
      } else
        allInvoiceData.push({
          title: "Maksusuoritukset",
          totalAmount: 0,
          count: 0,
        });
      if (unPaidInvoices.length > 0) {
        let data = {
          title: "Avoimet saatavat",
          totalAmount: unPaidInvoices[0].totalAmount,
          count: unPaidInvoices[0].count,
        };
        allInvoiceData.push(data);
      } else
        allInvoiceData.push({
          title: "Avoimet saatavat",
          totalAmount: 0,
          count: 0,
        });

      // Selling data
      if (sellsToday.length > 0) {
        let data = {
          title: "Myynti tänään",
          totalAmount: sellsToday[0].totalAmount,
        };
        allSellingData.push(data);
      } else
        allSellingData.push({
          title: "Myynti tänään",
          totalAmount: 0,
        });
      if (sellsThisMonth.length > 0) {
        let data = {
          title: "Myynti tässä kuussa",
          totalAmount: sellsThisMonth[0].totalAmount,
        };
        allSellingData.push(data);
      } else
        allSellingData.push({
          title: "Myynti tässä kuussa",
          totalAmount: 0,
        });
      if (sellsThisYear.length > 0) {
        let data = {
          title: "Myynti tänä vuonna",
          totalAmount: sellsThisYear[0].totalAmount,
        };
        allSellingData.push(data);
      } else
        allSellingData.push({
          title: "Myynti tänä vuonna",
          totalAmount: 0,
        });
      if (averageSell.length > 0) {
        let data = {
          title: "Kaupan keskihinta",
          totalAmount: averageSell[0].avgAmount,
        };
        allSellingData.push(data);
      } else
        allSellingData.push({
          title: "Kaupan keskihinta",
          totalAmount: 0,
        });

      // Assing to variables
      this.invoiceStats = allInvoiceData;
      this.sellingStats = allSellingData;
    },
    createCustomerStats(item) {
      let { allCustomers } = item;
      let all = [];

      if (allCustomers.length > 0) {
        let data = {
          title: "Asiakkaita yhteensä",
          count: allCustomers[0].count,
        };
        all.push(data);
      } else {
        all.push({ title: "Asiakkaita yhteensä", count: 0 });
      }
      this.customerStats = all;
    },
    createCustomerSells(item) {
      let { top10Customers } = item;
      let all = [];
      if (top10Customers.length > 0) {
        top10Customers.forEach((el) => {
          let data = {
            name: el._id.name,
            totalAmount: el.totalAmount,
          };
          all.push(data);
        });
      }
      this.customerSells = all;
    },

    createDailyStats(item) {
      let { dailySells } = item;
      let sells = [];
      let daysArr = [];

      let today = new Date();
      let lastDayDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      let lastDayOfThisMonth = lastDayDate.getDate();

      for (let i = 1; i < lastDayOfThisMonth + 1; i++) {
        // Make days array
        daysArr.push(i);
        // Make sells array
        sells.push(0);
        dailySells.forEach((el) => {
          if (el._id == i) {
            sells.pop();
            sells.push(el.totalAmount);
          }
        });
      }
      this.thisMonthsDaysArr = daysArr;
      this.dailyData = sells;
      this.dailyLoaded = true;
    },

    createMonthlyStats(item) {
      let { monthlySells } = item;
      let sells = [];

      for (let i = 1; i < 13; i++) {
        sells.push(0);
        monthlySells.forEach((el) => {
          if (el._id == i) {
            sells.pop();
            sells.push(el.totalAmount);
          }
        });
      }

      this.monthlyData = sells;
      this.monthlyLoaded = true;
    },

    createYearlyStats(item) {
      let { yearlySells } = item;
      const thisYear = new Date().getFullYear();
      const fiveYears = thisYear - 4;

      const sells = [];
      const years = [];

      for (let i = fiveYears; i <= thisYear; i++) {
        sells.push(0);
        years.push(String(i));
        yearlySells.forEach((el) => {
          if (el._id == i) {
            sells.pop();
            sells.push(el.totalAmount);
          }
        });
      }

      this.yearlyData = sells;
      this.yearLabels = years;
      this.yearlyLoaded = true;
    },
    createCashFlowStats(item) {
      let { cashFlowThisMonth, cashFlowNextMonth } = item;
      let arr = [];
      // let months = [
      //   "tammikuu",
      //   "helmikuu",
      //   "maaliskuu",
      //   "huhtikuu",
      //   "toukokuu",
      //   "kesäkuu",
      //   "heinäkuu",
      //   "elokuu",
      //   "syyskuu",
      //   "lokakuu",
      //   "marraskuu",
      //   "joulukuu"
      // ];

      if (cashFlowThisMonth.length > 0) {
        arr.push({
          title: `Saatavat kuluvan kuun loppuun`,
          totalAmount: cashFlowThisMonth[0].totalAmount,
        });
      } else {
        arr.push({
          title: `Saatavat kuluvan kuun loppuun`,
          totalAmount: 0,
        });
      }

      if (cashFlowNextMonth.length > 0) {
        arr.push({
          title: `Saatavat ensi kuussa`,
          totalAmount: cashFlowNextMonth[0].totalAmount,
        });
      } else {
        arr.push({
          title: `Saatavat ensi kuussa`,
          totalAmount: 0,
        });
      }

      this.cashFlowStats = arr;
    },

    createProductStats(item) {
      let { allProducts } = item;
      let all = [];

      if (allProducts.length > 0) {
        let data = {
          title: "Tuotteita yhteensä",
          count: allProducts[0].count,
        };
        all.push(data);
      }

      this.productStats = all;
    },
    createProductSells(item) {
      let { top10Products } = item;

      let all = [];
      if (top10Products.length > 0) {
        top10Products.forEach((el) => {
          let data = {
            name: `Id: ${el._id.productId}`,
            totalAmount: el.totalAmount,
            count: el.count,
          };
          all.push(data);
        });
      }
      this.productSells = all;
    },
    async getInvoiceReport() {
      if (this.$refs.reportForm.validate()) {
        if (new Date(this.dates.startDate).getTime() > new Date(this.dates.endDate).getTime()) {
          return this.showPopup("Aloituspäivä ei voi olla suurempi kuin päättymispäivä", "error");
        }

        try {
          let res = await axiosMethods.createOne("/api/v1/statistics/getInvoiceReport", this.dates);

          if (res.data.data.length == 0) {
            this.showInvoiceData = false;
            this.invoiceData.count = 0;
            this.invoiceData.totalAmount = 0;
            return this.showPopup("Ei tuloksia, kokeile muita päivämääriä", "error");
          } else {
            this.showInvoiceData = true;
            this.invoiceData.count = res.data.data[0].count;
            this.invoiceData.totalAmount = res.data.data[0].totalAmount;
          }
        } catch (err) {
          this.showPopup(err, "error");
        }
      }
    },
  },
};
</script>

<style scoped>
.card {
  height: 100%;
}

.invoice-data {
  background-color: rgb(240, 240, 240);
  padding: 20px;
}

.invoice-data p {
  margin-bottom: 0px;
  font-weight: 600;
}
</style>
