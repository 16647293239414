<template>
  <v-container>
    <v-form ref="form">
      <v-container>
        <v-row class="justify-center">
          <v-col cols="10" md="7" lg="5" xl="4">
            <h1 class="mt-5 mb-5">Unohtuiko salasanasi?</h1>
            <v-text-field v-model="email" label="Email"></v-text-field>
          </v-col>
        </v-row>

        <v-row class="justify-center">
          <v-col cols="10" md="7" lg="5" xl="4">
            <v-btn color="success" class="mr-4" @click.prevent="submit">Uusi salasana</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
import mixins from '../../mixins/mixins';
import axios from 'axios';
import axiosMethods from '../../mixins/axios';

export default {
  title: 'Onohtuiko salasana?',
  mixins: [mixins],
  data() {
    return {
      email: '',
    };
  },
  methods: {
    async submit() {
      let email = this.email;
      try {
        await axios({ method: 'post', url: '/api/v1/user/forgotPassword', data: { email } });
        this.showPopup('Antamaasi sähköpostiin on lähetetty linkki salasanan uusimikseksi. Linkki on voimassa 10 min.', 'success');
      } catch (err) {
        let error = await axiosMethods.validateRequest(err);
        this.showPopup(error, 'error');
      }
    },
  },
};
</script>

<style></style>
