import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

// Translation provided by Vuetify (javascript)
import fi from 'vuetify/es5/locale/fi';

// // Translation provided by Vuetify (typescript)
// import pl from 'vuetify/src/locale/pl';

// // Your own translation file
// import sv from './i18n/vuetify/sv';

Vue.component('my-component', {
  methods: {
    changeLocale() {
      this.$vuetify.lang.current = 'fi';
    },
  },
});

export default new Vuetify({
  lang: {
    locales: { fi },
    current: 'fi',
  },
});
