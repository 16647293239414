<template>
  <v-container>
    <h1 class="mt-5">Luo uusi lasku</h1>
    <v-form ref="form">
      <v-container>
        <!-- Lähettäjä -->
        <v-row>
          <v-col md="4">
            <h2 class="mt-5">Lähettäjä</h2>
            <v-autocomplete
              @change="getProfile($event)"
              item-text="name"
              item-value="_id"
              :items="profiles"
              label="Lähettäjä"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3">
            <v-text-field
              dense
              v-model="profileData.name"
              :rules="required"
              label="Nimi"
              disabled
            ></v-text-field>
          </v-col>
          <v-col v-if="profileData.isCompany" cols="12" md="3">
            <v-text-field
              dense
              v-model="profileData.businessId"
              :rules="required"
              label="Y-tunnus"
              disabled
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              dense
              v-model="profileData.email"
              :rules="required.concat(emailRules)"
              label="Email"
              disabled
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              dense
              v-model="profileData.address"
              label="Osoite"
              disabled
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              dense
              v-model="profileData.zipCode"
              label="Postinumero"
              disabled
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field dense v-model="profileData.city" label="Kaupunki" disabled></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field dense v-model="profileData.phone" label="Puhelin" disabled></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field dense v-model="profileData.fax" label="Faksi" disabled></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              dense
              v-model="profileData.bank"
              :rules="required"
              label="Pankki"
              disabled
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              dense
              v-model="profileData.iban"
              :rules="required"
              label="Tilinumero"
              disabled
            ></v-text-field>
          </v-col>
        </v-row>

        <!-- Vastaanottaja -->
        <v-row>
          <v-col md="4">
            <h2 class="mt-5">Vastaanottaja</h2>
            <v-checkbox
              @change="getGroups()"
              v-model="isGroup"
              :label="`Luo lasku ryhmälle`"
            ></v-checkbox>
            <v-autocomplete
              v-if="!isGroup"
              @change="getCustomer($event)"
              item-text="name"
              item-value="_id"
              :items="customers"
              label="Vastaanottaja"
            ></v-autocomplete>
            <v-autocomplete
              v-if="isGroup"
              @change="getGroup($event)"
              item-text="name"
              item-value="_id"
              :items="groups"
              label="Ryhmä"
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row v-if="!isGroup">
          <v-col cols="12" md="3">
            <v-text-field
              dense
              v-model="customerData.name"
              :rules="required"
              label="Nimi"
              disabled
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3" v-if="customerData.isCompany">
            <v-text-field
              dense
              v-model="customerData.businessId"
              label="Y-tunnus"
              disabled
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field dense v-model="customerData.email" label="Email"></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field dense v-model="customerData.address" label="Osoite"></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field dense v-model="customerData.zipCode" label="Postinumero"></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field dense v-model="customerData.city" label="Kaupunki"></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field dense v-model="customerData.phone" label="Puhelin"></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field dense v-model="customerData.fax" label="Faksi"></v-text-field>
          </v-col>
        </v-row>

        <!-- Muut tiedot -->
        <v-row>
          <v-col md="4">
            <h2 class="mt-5">Muut tiedot</h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3">
            <v-text-field
              dense
              type="number"
              v-model.number="invoiceData.invoiceNumber"
              :rules="required"
              label="Laskun numero"
              disabled
            ></v-text-field>
          </v-col>
          <v-col v-if="useReferenceNumber" cols="12" md="3">
            <v-text-field
              value
              dense
              v-model="invoiceData.referenceNumber"
              label="Viitenumero"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-menu
              ref="menu1"
              v-model="menu1"
              :close-on-content-click="false"
              transition="scale-transition"
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  dense
                  v-model="dateFormatted"
                  label="Eräpäivä"
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                first-day-of-week="1"
                v-model="invoiceData.dueDate"
                @input="menu1 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              dense
              v-model="profileData.paymentTerms"
              label="Maksuehdot"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              dense
              value="service"
              v-model="invoiceData.service"
              label="Toimitus"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              dense
              type="number"
              value="timeDiscount1"
              v-model.number="profileData.timeDiscount1"
              :rules="positiveNumber"
              label="Kassa-alennus 7 päivää (%)"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              dense
              type="number"
              value="timeDiscount2"
              v-model.number="profileData.timeDiscount2"
              :rules="positiveNumber"
              label="Kassa-alennus 14 päivää (%)"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              dense
              type="number"
              value="penaltyInterest"
              v-model.number="profileData.penaltyInterest"
              :rules="positiveNumber"
              label="Viivästyskorko (%)"
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="3">
            <v-text-field
              dense
              type="number"
              v-model.number="profileData.reminderPayment"
              :rules="positiveNumber"
              label="Muistutusmaksu (€)"
            ></v-text-field>
          </v-col>
        </v-row>

        <!-- Tuotteet -->
        <v-row>
          <v-col md="4">
            <h2 class="mt-5 mb-5">Tuotteet</h2>
          </v-col>
        </v-row>
        <div v-for="(product, index) in products" :key="index">
          <v-row class="mb-5">
            <v-divider></v-divider>
          </v-row>
          <v-row>
            <v-col cols="6" md="2" lg="1">
              <v-combobox
                outlined
                dense
                v-model="product.productId"
                @change="getProduct($event, product)"
                item-text="productId"
                item-value="_id"
                :items="searchedProducts"
                label="Id"
              ></v-combobox>
            </v-col>

            <v-col cols="6" md="3" lg="2">
              <v-text-field
                outlined
                dense
                v-model="product.name"
                :rules="required"
                label="Nimi"
              ></v-text-field>
            </v-col>

            <v-col cols="6" md="2" lg="2">
              <v-text-field
                outlined
                dense
                v-model.number="product.price"
                step="0.01"
                @input="parseValue(index)"
                type="number"
                :rules="required.concat(positiveNumber)"
                label="Hinta"
              />
            </v-col>

            <v-col cols="6" md="2" lg="2">
              <v-text-field
                outlined
                dense
                v-model.number="product.quantity"
                type="number"
                step="1"
                :rules="required"
                label="Määrä"
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="2" lg="2">
              <v-select
                outlined
                dense
                v-model="product.vat"
                :items="vatType"
                label="Alv-kanta"
                return-object
              ></v-select>
            </v-col>
            <v-col cols="12" md="3" lg="3">
              <div class="details-container">
                <v-text-field
                  style="width: 60%"
                  outlined
                  dense
                  v-model="product.details"
                  label="Ominaisuudet"
                ></v-text-field>
                <p class="ml-10 mt-2 delete-btn" text @click="deleteProduct(index)">X</p>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-divider></v-divider>
          </v-row>
        </div>
        <v-row v-if="products.length > 0">
          <v-col cols="12" md="2" lg="1">Yhteensä</v-col>
          <v-col cols="12" md="3" lg="2"></v-col>
          <v-col cols="12" md="2" lg="2">{{ parseFloat(totalSum).toFixed(2) }} € (Alv 0 %)</v-col>
          <v-col cols="12" md="2" lg="2">{{ totalQuantity }} kpl</v-col>
          <v-col cols="12" md="2" lg="2"></v-col>
          <v-col cols="12" md="2" lg="3"></v-col>
        </v-row>
        <v-row class="mt-5 mb-5">
          <v-col>
            <v-btn color="info" class="mr-4 mb-5" @click="addProduct">Lisää tuote</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn color="success" class="mr-4 mt-5" @click="submit">Luo lasku</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
import axios from "axios";
import axiosMethods from "../../mixins/axios";
import mixins from "../../mixins/mixins";

export default {
  title: "Uusi lasku",
  mixins: [mixins],
  data: (vm) => {
    return {
      isGroup: false,
      groups: [],
      group: "",
      vatType: ["Alv 25.5 %", "Alv 24 %", "Alv 0 %"],
      date: new Date().toISOString().substr(0, 10),
      dateFormatted: vm.formatDate(new Date().toISOString().substr(0, 10)),
      menu1: false,
      profiles: [],
      customers: [],
      products: [],
      searchedProducts: [],
      useReferenceNumber: true,
      profileData: {
        _id: "",
        name: "",
        businessId: "",
        email: "",
        address: "",
        zipCode: "",
        city: "",
        phone: "",
        fax: "",
        bank: "",
        iban: "",
        paymentTerms: "",
        timeDiscount1: "",
        timeDiscount2: "",
        reminderPayment: "",
        reminderInterval: "",
        penaltyInterest: "",
      },
      customerData: {
        _id: "",
        name: "",
        businessId: "",
        email: "",
        address: "",
        zipCode: "",
        city: "",
        phone: "",
        fax: "",
        bank: "",
        iban: "",
      },
      invoiceData: {
        invoiceNumber: null,
        referenceNumber: vm.calcReferenceNumber(1),
        dueDate: new Date().toISOString().substr(0, 10),
        service: "",
      },
      emailRules: [(v) => /.+@.+\..+/.test(v) || "Email ei ole oikeassa muodossa"],
      required: [(v) => !!v || "Pakollinen kenttä"],
      positiveNumber: [(v) => vm.checkNumber(v) || "Anna positiivinen luku"],
    };
  },
  async created() {
    try {
      let profiles = await axiosMethods.get("/api/v1/profile/getAll");
      let customers = await axiosMethods.get("/api/v1/customer/getAll");
      let invoiceNumber = await axiosMethods.get("/api/v1/invoice/getNextInvoiceNumber");
      let products = await axiosMethods.get("/api/v1/product/getAll");
      let currentUser = await axiosMethods.get("/api/v1/user/getCurrentUser");

      this.invoiceData.invoiceNumber = invoiceNumber.data.invoiceNumber;
      this.profiles = profiles.data.data;
      this.customers = customers.data.data;
      this.searchedProducts = products.data.products;
      this.useReferenceNumber = currentUser.data.currentUser.useReferenceNumber;
    } catch (err) {
      this.showPopup(err, "error");
    }
  },
  watch: {
    "invoiceData.dueDate"() {
      this.dateFormatted = this.formatDate(this.invoiceData.dueDate);
    },
    "invoiceData.invoiceNumber"() {
      this.invoiceData.referenceNumber = this.calcReferenceNumber(this.invoiceData.invoiceNumber);
    },
  },
  computed: {
    totalSum() {
      let sum = 0;
      this.products.forEach((el) => {
        sum += el.price * el.quantity;
      });
      return sum;
    },
    totalQuantity() {
      let sum = 0;
      this.products.forEach((el) => {
        sum += el.quantity;
      });
      return sum;
    },
  },
  methods: {
    async getGroups() {
      if (this.isGroup) {
        try {
          let res = await axiosMethods.get("/api/v1/group/getAll");
          this.groups = res.data.groups;
        } catch (err) {
          this.showPopup(err, "error");
        }
      }
    },
    getGroup(event) {
      this.group = event;
    },
    checkNumber(v) {
      if (v > 0 || v == "") return true;
      else return false;
    },
    parseValue(index) {
      let price = this.products[index].price;
      if (price) this.products[index].price = parseFloat(this.products[index].price.toFixed(2));
    },
    calcReferenceNumber(numbers) {
      let numArr = numbers.toString().split("");
      // Do the multiply math, from right to left 7, 3, 1
      let numArrReversed = numArr.reverse();
      let multiplier = [7, 3, 1];
      let multiplyNums = [];
      let sum = 0;
      let checkDigit;
      // Make multiplyNums array as long as numArrReversed
      for (let i = 0; multiplyNums.length < numArrReversed.length; i++) {
        for (let j = 0; j < 3; j++) {
          multiplyNums.push(multiplier[j]);
          if (multiplyNums.length == numArrReversed.length) {
            break;
          }
        }
      }
      // Multiply arrays together
      for (let i = 0; i < numArrReversed.length; i++) {
        sum += numArrReversed[i] * multiplyNums[i];
      }
      // Prereference number arrays 2 last digits
      let preReferenceNum = sum.toString().split("").slice(-2);

      if (preReferenceNum.length < 2) {
        checkDigit = 10 - preReferenceNum[0];
      } else {
        if (preReferenceNum[1] == 0) {
          checkDigit = 0;
        } else {
          checkDigit = 10 - preReferenceNum[1];
        }
      }
      numArr.reverse();
      numArr.push(checkDigit);

      //Split to 5 digit parts
      if (numArr.length > 5) {
        for (let i = 0; i < numArr.length; i += 6) {
          numArr.splice(i, 0, " ");
        }
        numArr.shift();
      }

      let referenceNumber = numArr.join("");
      return referenceNumber;
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}.${month}.${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split(".");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    async getProfile(id) {
      try {
        let res = await axiosMethods.get("/api/v1/profile/getOne/" + id);
        let profileData = res.data.data;

        let keys = Object.keys(profileData);

        keys.forEach((key) => {
          if (profileData[key] == null) {
            profileData[key] = "";
          }
        });

        this.profileData = profileData;
      } catch (err) {
        this.showPopup("Profiilin haku epäonnistui " + err.response.data.message, "alert");
      }
    },
    async getCustomer(id) {
      try {
        let res = await axiosMethods.get("/api/v1/customer/getOne/" + id);
        this.customerData = res.data.data;
      } catch (err) {
        this.showPopup(err, "error");
      }
    },
    async getProduct(event, product) {
      if (event) {
        try {
          let found = await axiosMethods.get("/api/v1/product/getOne/" + event.productId);

          if (found.data.product) {
            product.productId = found.data.product.productId;
            product.name = found.data.product.name;
            product.price = found.data.product.price;
          }
        } catch (err) {
          this.showPopup("Tuotteen haku epäonnistui " + err.response.data.message, "alert");
        }
      }
    },
    addProduct() {
      this.products.push({
        productId: "",
        name: "",
        price: null,
        quantity: null,
        vat: "Alv 25.5 %",
        details: "",
      });
    },

    deleteProduct(index) {
      this.products.splice(index, 1);
    },

    async submit() {
      // Test if number
      if (isNaN(this.totalSum)) return;

      if (this.$refs.form.validate()) {
        if (this.products.length == 0) {
          return this.showPopup("Lisää ainakin yksi tuote", "error");
        }

        // Check userReferenceNumber
        let otherData = this.invoiceData;

        if (!this.useReferenceNumber) {
          otherData["referenceNumber"] = "";
        }

        // Single User
        if (!this.isGroup) {
          try {
            let singleData = {
              profile: this.profileData,
              customer: this.customerData,
              products: this.products,
              otherData,
            };

            await axios.post("/api/v1/invoice/create", singleData);
            this.showPopup("Uusi lasku luotu", "success");
            setTimeout(() => {
              this.$router.push("/invoices");
            }, 800);
          } catch (err) {
            let error = await axiosMethods.validateRequest(err);
            this.showPopup(error, "error");
          }
          // Group
        } else if (this.isGroup) {
          // Check that group is selected
          if (!this.group) {
            return this.showPopup("Lisää ensin ryhmä", "error");
          }
          try {
            let multipleData = {
              profile: this.profileData,
              group: this.group,
              products: this.products,
              otherData,
            };

            await axios.post("/api/v1/invoice/createMultiple", multipleData);
            this.showPopup("Uudet laskut luotu", "success");
            setTimeout(() => {
              this.$router.push("/invoices");
            }, 800);
          } catch (err) {
            let error = await axiosMethods.validateRequest(err);
            this.showPopup(error, "error");
          }
        }
      }
    },
  },
};
</script>

<style scoped>
.delete-btn {
  cursor: pointer;
  color: red;
}
.details-container {
  display: flex;
  justify-content: center;
  align-content: center;
  height: 100%;
}
</style>
