<template>
  <v-container>
    <h1 class="mt-5">Luo uusi laskutusprofiili</h1>
    <v-form ref="form">
      <v-container>
        <v-row>
          <v-col cols="12" md="4">
            <v-radio-group v-model="profileData.isCompany" :mandatory="true">
              <v-radio label="Yksityinen" :value="false"></v-radio>
              <v-radio label="Yritys" :value="true"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field v-model="profileData.name" :rules="required" label="Nimi"></v-text-field>
          </v-col>
          <v-col v-if="profileData.isCompany" cols="12" md="4">
            <v-text-field v-model="profileData.businessId" :rules="required" label="Y-tunnus"></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field v-model="profileData.email" :rules="required.concat(emailRules)" label="Email"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field v-model="profileData.address" label="Osoite"></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field v-model="profileData.zipCode" label="Postinumero"></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field v-model="profileData.city" label="Kaupunki"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field v-model="profileData.phone" label="Puhelin"></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field v-model="profileData.fax" label="Faksi"></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field v-model="profileData.bank" :rules="required" label="Pankki"></v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field v-model="profileData.iban" :rules="required" label="Tilinumero"></v-text-field>
          </v-col>
        </v-row>
        <v-btn color="success" class="mr-4" @click="submit">Luo</v-btn>
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
import axiosMethods from '../../mixins/axios';
import mixins from '../../mixins/mixins';

export default {
  title: 'Uusi profiili',
  mixins: [mixins],
  data() {
    return {
      profileData: {
        isCompany: true,
        name: '',
        businessId: '',
        email: '',
        address: '',
        zipCode: '',
        city: '',
        phone: '',
        fax: '',
        bank: '',
        iban: '',
        paymentTerms: '',
        penaltyInterest: null,
        reminderPayment: null,
        reminderInterval: null,
      },
      emailRules: [(v) => /.+@.+\..+/.test(v) || 'Email ei ole oikeassa muodossa'],
      required: [(v) => !!v || 'Pakollinen kenttä'],
      positiveNumber: [(v) => v > 0 || 'Anna positiivinen luku'],
    };
  },
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        try {
          await axiosMethods.createOne('/api/v1/profile/create', this.profileData);
          this.showPopup('Uusi profiili luotu', 'success');
        } catch (err) {
          this.showPopup('Profiilin luonti epäonnistui ' + err, 'error');
        }
        //  reset
        // this.$refs.form.reset();
        // }
      }
    },
  },
};
</script>

<style></style>
