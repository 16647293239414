<template>
  <v-container>
    <div class="mt-5">
      <v-row class="justify-center">
        <v-col md="4">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Etsi"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>

      <v-data-table
        multi-sort
        :search="search"
        :headers="headers"
        :items="products"
        v-model="selected"
        show-select
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Tuotteet</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
          </v-toolbar>
        </template>

        <template v-slot:[`item.productId`]="props">
          <v-edit-dialog :return-value.sync="props.item.productId">
            <div>{{ props.item.productId }}</div>
            <template v-slot:input>
              <v-text-field
                v-model="props.item.productId"
                label="Muokkaa"
                single-line
                counter
                autofocus
                :rules="required"
              ></v-text-field>
              <v-btn @click="saveItem(props.item)" class="mb-3 success">Tallenna</v-btn>
            </template>
          </v-edit-dialog>
        </template>

        <template v-slot:[`item.name`]="props">
          <v-edit-dialog :return-value.sync="props.item.name">
            <div>{{ props.item.name }}</div>
            <template v-slot:input>
              <v-text-field
                v-model="props.item.name"
                label="Muokkaa"
                single-line
                counter
                autofocus
              ></v-text-field>
              <v-btn @click="saveItem(props.item)" class="mb-3 success">Tallenna</v-btn>
            </template>
          </v-edit-dialog>
        </template>

        <template v-slot:[`item.price`]="props">
          <v-edit-dialog :return-value.sync="props.item.price">
            <div>{{ formatPrice(props.item.price) }}</div>
            <template v-slot:input>
              <v-text-field
                v-model="props.item.price"
                label="Muokkaa"
                single-line
                counter
                autofocus
              ></v-text-field>
              <v-btn @click="saveItem(props.item)" class="mb-3 success">Tallenna</v-btn>
            </template>
          </v-edit-dialog>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-icon title="Poista" small class="mr-5" @click="deleteItem(item)">mdi-delete</v-icon>
        </template>

        <template v-slot:no-data>
          <h2>Ei tuotteita</h2>
          <small>Tuotteet luodaan laskun teon yhteydessä!</small>
        </template>
      </v-data-table>

      <v-radio-group v-model="boolean">
        <v-radio label="Näkyville" value="true"></v-radio>
        <v-radio label="Piiloon" value="false"></v-radio>
      </v-radio-group>
      <v-btn @click="markAsAvailable" color="primary" dark class="mb-2 mt-5"
        >Muuta valittujen näkyvyys</v-btn
      >
    </div>
  </v-container>
</template>

<script>
import axiosMethods from "../../mixins/axios";
import mixins from "../../mixins/mixins";

export default {
  title: "Tuotteet",
  mixins: [mixins],
  data: () => ({
    boolean: "true",
    selected: [],
    search: "",
    dialog: false,
    headers: [
      {
        text: "Tuotenumero",
        value: "productId",
      },
      { text: "Nimi", value: "name" },
      { text: "Profiili", value: "createdBy.name" },
      { text: "Hinta", value: "price" },
      { text: "Näkyvyys", value: "showToCustomers" },
      { text: "Toiminnot", value: "actions", sortable: false },
    ],
    products: [],
    required: [(v) => !!v || "Pakollinen kenttä"],
  }),
  async created() {
    try {
      let res = await axiosMethods.get("/api/v1/product/getAll");
      let products = res.data.products;

      products = products.map((el) => {
        el.showToCustomers = this.formatBoolean(el.showToCustomers);
        return el;
      });

      this.products = products;
    } catch (err) {
      this.showPopup(err, "error");
    }
  },
  methods: {
    async saveItem(item) {
      let data = {
        name: item.name,
        price: item.price,
        productId: item.productId,
      };

      try {
        await axiosMethods.updateOne("/api/v1/product/update/" + item._id, data);
        this.$router.go();
        this.showPopup("Tuote tallennettu", "success");
      } catch (err) {
        this.showPopup(err, "error");
      }
    },

    async deleteItem(item) {
      if (confirm("Haluatko varmasti poistaa tuotteen tuotenumerolla: " + item.productId)) {
        try {
          await axiosMethods.delete("/api/v1/product/delete/" + item._id);
          const index = this.products.findIndex((el) => String(el._id) == String(item._id));

          if (index != -1) {
            this.products.splice(index, 1);
            this.showPopup("Tuote poistettu", "success");
          }
        } catch (err) {
          this.showPopup(err, "error");
        }
      }
    },

    async markAsAvailable() {
      let products = this.selected;
      let productIds = products.map((el) => el._id);
      let data = { productIds, boolean: this.boolean };
      try {
        await axiosMethods.updateMany("/api/v1/product/updateAvailability/", data);
        this.$router.go();
        this.showPopup(`Valittujen tuotteiden näkyvyys on muutettu`, "success");
      } catch (err) {
        this.showPopup(err, "error");
      }
    },
  },
};
</script>

<style></style>
