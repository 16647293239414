<template>
  <v-container fluid>
    <div class="mt-5">
      <v-row class="justify-center">
        <v-col md="4">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Etsi"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        v-model="selected"
        show-select
        multi-sort
        :search="search"
        :headers="headers"
        :items="invoices"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Laskuni</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
          </v-toolbar>
        </template>
        <!-- TotalAmount -->
        <template v-slot:[`item.totalAmountWithTax`]="{ item }">
          <span>{{ formatPrice(totalAmountWithTax(item)) }}</span>
        </template>
        <!-- dueDate -->
        <template v-slot:[`item.dueDate`]="{ item }">
          <span :class="{ isLate: item.isLate && item.paid === 'Ei' }">{{ item.dueDate }}</span>
        </template>
        <!-- Paid -->
        <template v-slot:[`item.paid`]="{ item }">
          <span>{{ item.paid }}</span>
        </template>

        <!-- Show last send -->
        <template v-slot:[`item.lastSend`]="{ item }">
          <span>{{ item.lastSend }}</span>
        </template>
        <!-- Actions -->
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon title="Lataa lasku" small class="mr-5" @click="downloadPdf(item)"
            >mdi-file-pdf</v-icon
          >
          <v-icon
            v-if="item.isLate && item.paid === 'Ei'"
            title="Lataa muistutuslasku"
            small
            class="mr-5"
            @click="downloadReminderPdf(item)"
            >mdi-bell</v-icon
          >
        </template>
        <template v-slot:no-data>
          <h2>Ei laskuja</h2>
        </template>
      </v-data-table>
      <v-btn @click="downloadMultiplePdfs" color="primary" dark class="mb-2 mt-5"
        >Lataa valitut</v-btn
      >
    </div>
  </v-container>
</template>

<script>
import axios from "axios";
import axiosMethods from "../../../mixins/axios";
import mixins from "../../../mixins/mixins";
import download from "downloadjs";

export default {
  title: "Laskuni",
  mixins: [mixins],
  data: () => ({
    dialog: false,
    active: "1",
    selectedInvoice: "",
    selected: [],
    search: "",
    headers: [
      {
        text: "Lähettäjä",
        value: "profile.name",
      },
      {
        text: "Laskun numero",
        value: "invoiceNumber",
      },
      {
        text: "Summa sis. alv",
        value: "totalAmountWithTax",
      },
      {
        text: "Viitenumero.",
        value: "referenceNumber",
      },
      { text: "Laskun pvm.", value: "createdAt" },
      { text: "Eräpäivä", value: "dueDate" },

      { text: "Maksettu", value: "paid" },
      {
        text: "Toiminnot",
        value: "actions",
        sortable: false,
      },
    ],
    invoices: [],
    partPayments: [],
    partPayment: {
      id: null,
      invoiceNumber: null,
      price: null,
      itemTotalAmount: null,
      alreadyPaid: null,
      unPaid: null,
    },
  }),
  async created() {
    try {
      let res = await axiosMethods.get("/api/v1/invoice/getAll");
      let invoices = res.data.invoices;

      // Search form can only find these,
      invoices = invoices.map((el) => {
        el.dueDate = this.formatDate(el.dueDate);
        el.createdAt = this.formatDate(el.createdAt);
        el.lastSend = this.formatDateInvoices(el.lastSend);
        el.paid = this.formatBoolean(el.paid);
        return el;
      });
      this.invoices = invoices;
    } catch (err) {
      this.showPopup(err, "error");
    }
  },
  methods: {
    checkPaid(item) {
      return item.paid == "Kyllä" ? false : true;
    },
    totalAmountWithTax(item) {
      if (item.totalAmountWithTax) {
        return item.totalAmountWithTax;
      } else {
        return item.totalAmountVat24;
      }
    },
    async downloadPdf(item) {
      try {
        axios.defaults.headers.common["Authorization"] = "Bearer " + this.$store.getters.getToken;
        const res = await axios({
          method: "get",
          url: `/api/v1/invoice/downloadPdf/${item._id}`,
          responseType: "blob",
        });

        const contentType = res.headers["content-type"];
        download(res.data, `Lasku_${item.invoiceNumber}_${item.profile.name}.pdf`, contentType);

        // var blob = new Blob([res.data], {
        //     type: "application/pdf"
        //   }),
        //   fileURL = URL.createObjectURL(blob);

        // window.open(fileURL);
      } catch (err) {
        let error = await axiosMethods.validateRequest(err);
        this.showPopup(error, "error");
      }
    },
    async downloadReminderPdf(item) {
      try {
        axios.defaults.headers.common["Authorization"] = "Bearer " + this.$store.getters.getToken;
        const res = await axios({
          method: "get",
          url: `/api/v1/invoice/downloadReminderPdf/${item._id}`,
          responseType: "blob",
        });

        const contentType = res.headers["content-type"];
        download(
          res.data,
          `Muistutuslasku_${item.invoiceNumber}_${item.profile.name}.pdf`,
          contentType
        );

        // var blob = new Blob([res.data], {
        //     type: 'application/pdf',
        //   }),
        //   fileURL = URL.createObjectURL(blob);

        // window.open(fileURL);
      } catch (err) {
        let error = await axiosMethods.validateRequest(err);
        this.showPopup(error, "error");
      }
    },
    async downloadMultiplePdfs() {
      if (this.selected.length >= 1) {
        try {
          let ids = this.selected.map((item) => {
            return item._id;
          });

          axios.defaults.headers.common["Authorization"] = "Bearer " + this.$store.getters.getToken;
          const res = await axios({
            method: "post",
            url: `/api/v1/invoice/downloadMultiplePdfs/`,
            data: {
              ids,
            },
            responseType: "blob",
          });

          let fileURL = URL.createObjectURL(res.data);

          let link = document.createElement("a");
          link.href = fileURL;
          link.download = "Laskut.pdf";
          link.click();
          URL.revokeObjectURL(fileURL);
        } catch (err) {
          let error = await axiosMethods.validateRequest(err);
          this.showPopup(error, "error");
        }
      }
    },
    showReminderIcon(item) {
      if (item.paid) return true;
      return true;
    },
  },
};
</script>

<style scoped>
.isLate {
  color: red;
}

.partPayment {
  cursor: pointer;
  text-decoration-line: underline;
}

.popup-text {
  color: #1067b3;
  font-size: 15px;
}
</style>
