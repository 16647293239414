<template>
  <v-card class="mx-auto" max-width="500">
    <v-card-title>Myynnit alv (0)</v-card-title>
    <v-card-text>
      <div v-for="(value, key, idx) in customer" :key="'a' + idx">
        <p v-if="key == 'currentYear'" style="margin-bottom: 4px">
          Kuluva vuosi: {{ formatPrice(value) }}
        </p>
        <div v-if="key == 'lastFourYears'">
          <div v-for="(reversedKey, idx) in Object.keys(customer[key]).reverse()" :key="'b' + idx">
            <p style="margin-bottom: 4px">
              {{ reversedKey }}: {{ formatPrice(customer[key][reversedKey]) }}
            </p>
          </div>
        </div>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-btn @click="closeDialog" text color="#EF5350">Sulje</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import mixins from "../mixins/mixins";
export default {
  mixins: [mixins],
  props: ["customer"],
  data: () => {
    return {};
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog", false);
    },
  },
};
</script>

<style></style>
