<template>
  <v-container>
    <!-- Yritys -->
    <!-- <h1 class="mt-5">Yrityksen tiedot</h1>
    <v-form ref="form">
      <v-container>
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field v-model="profileData.name" :rules="required" label="Yrityksen nimi"></v-text-field>
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field v-model="profileData.businessId" :rules="required" label="Y-tunnus"></v-text-field>
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field v-model="profileData.email" label="Email"></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="4">
            <v-text-field v-model="profileData.address" :rules="required" label="Osoite"></v-text-field>
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field v-model="profileData.zipCode" :rules="required" label="Postinumero"></v-text-field>
          </v-col>

          <v-col cols="12" md="4">
            <v-text-field v-model="profileData.city" :rules="required" label="Kaupunki"></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" md="4">
            <v-text-field v-model="profileData.phone" label="Puhelin"></v-text-field>
          </v-col>
        </v-row>

        <v-btn color="success" class="mr-4" @click="submit">Tallenna yritystiedot</v-btn>
      </v-container>
    </v-form>

    <br />
    <hr />-->

    <!-- Omat tiedot -->
    <h1 class="mt-5">Käyttäjätiedot</h1>
    <v-form ref="form">
      <v-row>
        <v-col cols="12" md="4" sm="5">
          <v-text-field v-model="name" label="Nimi"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4" sm="5">
          <v-text-field v-model="email" label="Email (käyttäjänimi)"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn color="success" class="mr-4 mt-5" @click="updateUserData">Tallenna käyttäjätiedot</v-btn>
        </v-col>
      </v-row>
    </v-form>

    <br />
    <hr />

    <!-- Salasana -->
    <h2 class="mt-5">Päivitä salasana</h2>
    <v-form ref="form">
      <v-row>
        <v-col cols="12" md="4" sm="5">
          <v-text-field v-model="password" type="password" label="Uusi salasana"></v-text-field>
        </v-col>
        <v-col cols="12" md="4" sm="5">
          <v-text-field v-model="passwordConfirm" type="password" label="Toista salasana"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4" sm="5">
          <v-text-field v-model="currentPassword" type="password" label="Nykyinen salasana"></v-text-field>
          <small>Päivittääksesi salasanasi, sinun tulee antaa myös nykyinen salasanasi</small>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn color="success" class="mr-4 mt-5" @click="updatePassword">Päivitä salasana</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import axiosMethods from "../../../mixins/axios";
import mixins from "../../../mixins/mixins";

export default {
  title: "Muokkaa asiakasta",
  mixins: [mixins],
  data() {
    return {
      name: "",
      email: "",
      password: "",
      passwordConfirm: "",
      currentPassword: "",
      profileData: {},
      emailRules: [
        v => /.+@.+\..+/.test(v) || "Email ei ole oikeassa muodossa"
      ],
      required: [v => !!v || "Pakollinen kenttä"],
      positiveNumber: [v => v > 0 || "Anna positiivinen luku"]
    };
  },
  async created() {
    try {
      let res = await axiosMethods.get("/api/v1/profile/getOwnProfile/");
      let res2 = await axiosMethods.get("/api/v1/user/getCurrentUser");

      this.profileData = res.data.profile;
      this.name = res2.data.currentUser.name;
      this.email = res2.data.currentUser.email;
    } catch (err) {
      this.showPopup(err, "error");
    }
  },
  methods: {
    // async submit() {
    //   if (this.$refs.form.validate()) {
    //     try {
    //       await axiosMethods.updateOne(
    //         "/api/v1/profile/updateOnwProfile/",
    //         this.profileData
    //       );
    //       this.showPopup("Profiili päivitetty", "success");
    //     } catch (err) {
    //       this.showPopup(err, "error");
    //     }
    //   }
    // },
    async updateUserData() {
      try {
        await axiosMethods.updateOne("/api/v1/user/updateUser", {
          nextInvoiceNumber: this.nextInvoiceNumber,
          email: this.email,
          name: this.name
        });
        this.$router.go();
        this.showPopup("Tiedot päivitetty", "success");
      } catch (err) {
        this.$router.go();
        this.showPopup(err, "error");
      }
    },
    async updatePassword() {
      try {
        let res = await axiosMethods.updateOne(
          "/api/v1/user/updateMyPassword",
          {
            password: this.password,
            passwordConfirm: this.passwordConfirm,
            currentPassword: this.currentPassword
          }
        );
        let token = res.data.token;
        localStorage.setItem("token", token);
        this.$store.commit("setToken", token);

        this.showPopup("Salasana päivitetty", "success");
        this.password = "";
        this.passwordConfirm = "";
        this.currentPassword = "";
      } catch (err) {
        this.showPopup(err, "error");
        this.password = "";
        this.passwordConfirm = "";
        this.currentPassword = "";
      }
    }
  }
};
</script>

<style></style>
