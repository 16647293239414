<template>
  <v-container>
    <v-form ref="form">
      <v-container>
        <v-row class="justify-center">
          <v-col cols="10" md="7" lg="5" xl="4">
            <h1 class="mt-5 mb-5">Uusi salasana</h1>
            <v-text-field v-model="password" type="password" label="Salasana"></v-text-field>
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col cols="10" md="7" lg="5" xl="4">
            <v-text-field
              v-model="passwordConfirm"
              type="password"
              label="Toista salasana"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="justify-center">
          <v-col cols="10" md="7" lg="5" xl="4">
            <v-btn color="success" class="mr-4" @click.prevent="reset">Tallenna</v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
import mixins from "../../mixins/mixins";
import axios from "axios";
import axiosMethods from "../../mixins/axios";

export default {
  title: "Salasanan uusiminen",
  mixins: [mixins],
  data() {
    return {
      passwordConfirm: "",
      password: "",
    };
  },
  methods: {
    async reset() {
      try {
        await axios({
          method: "patch",
          url: "/api/v1/user/resetPassword/" + this.$route.params.token,
          data: {
            password: this.password,
            passwordConfirm: this.passwordConfirm,
          },
        });
        this.$router.push("/login");
        this.showPopup("Salasana päivitetty", "success");
      } catch (err) {
        let error = await axiosMethods.validateRequest(err);

        this.showPopup(error, "error");
      }
    },
  },
};
</script>

<style></style>
