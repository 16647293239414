import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import invoice from '../store/modules/invoice'
import browsehistory from '../store/modules/browseHistory'

Vue.use(Vuex);

let cart = localStorage.getItem('cart');

export default new Vuex.Store({
  state: {
    user: localStorage.getItem('user') || null,
    token: localStorage.getItem('token') || null,
    cart: cart ? JSON.parse(cart) : [],
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
    deleteUser(state) {
      state.user = null;
    },
    setToken(state, token) {
      state.token = token;
    },
    deleteToken(state) {
      state.token = null;
    },
    addToCart(state, item) {
      let found = state.cart.find((product) => product.productId == item.productId && product.name == item.name);
      if (found) {
        found.quantity++;
      } else {
        state.cart.push(item);
      }
      this.commit('saveData');
    },
    decreaseQuantity(state, item) {
      let found = state.cart.find((product) => product.productId == item.productId);
      if (found.quantity > 1) {
        found.quantity--;
      }
      this.commit('saveData');
    },
    removeFromCart(state, item) {
      let index = state.cart.indexOf(item);
      state.cart.splice(index, 1);
      this.commit('saveData');
    },
    emptyCart() {
      localStorage.removeItem('cart');
    },
    saveProductInformation(state, data) {
      let found = state.cart.find(
        (product) => product.productId == data.product.productId && product.name == data.product.name
      );

      found.details = data.event;
      this.commit('saveData');
    },
    saveData(state) {
      localStorage.setItem('cart', JSON.stringify(state.cart));
    },
  },
  actions: {
    getToken(ctx, credentials) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/v1/user/login`, {
            email: credentials.email,
            password: credentials.password,
          })
          .then((res) => {
            localStorage.setItem('token', res.data.token);
            ctx.commit('setToken', res.data.token);
            localStorage.setItem('user', res.data.user.role);
            ctx.commit('setUser', res.data.user.role);
            resolve(res);
          })
          .catch((err) => {
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            reject(err.response.data.message);
          });
      });
    },
    deleteToken(ctx) {
      if (ctx.getters.isLoggedIn) {
        new Promise((resolve) => {
          localStorage.removeItem('token');
          localStorage.removeItem('user');
          ctx.commit('deleteToken');
          ctx.commit('deleteUser');
          resolve('success');
        });
      }
    },
  },
  getters: {
    isLoggedIn(state) {
      return state.token !== null;
    },
    getToken(state) {
      return state.token;
    },
    getRole(state) {
      return state.user;
    },
    getCart(state) {
      return state.cart;
    },
  },
  modules: {invoice, browsehistory},
});
