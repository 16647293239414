var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',{staticClass:"mt-5"},[_c('v-row',{staticClass:"justify-center"},[_c('v-col',{attrs:{"md":"4"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Etsi","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.customers,"show-select":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Asiakkaat")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('router-link',{attrs:{"to":"/customer/create"}},[_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""}},[_vm._v("Uusi asiakas")])],1)],1)]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"color":"#42a5f5","cursor":"pointer"},on:{"click":function($event){return _vm.showInfo(item)}}},[_vm._v(_vm._s(item.name))])]}},{key:"item.merchantRequestSend",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatMessageSend(item.merchantRequestSend)))])]}},{key:"item.merchantRequestAccepted",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.formatMessageSend(item.merchantRequestAccepted)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-5",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("mdi-delete")])]}},{key:"no-data",fn:function(){return [_c('h2',[_vm._v("Ei asiakkaita")])]},proxy:true}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('customer-statistics',{attrs:{"customer":_vm.customer},on:{"closeDialog":function($event){_vm.dialog = false}}})],1),_c('v-btn',{staticClass:"mb-2 mt-5",attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.createMultipleCustomers()}}},[_vm._v("Lähetä pyyntö valituille")]),_c('br'),_c('small',[_vm._v("Asiakas saa sähköpostin, jossa häntä pyydetään rekisteröitymään palveluun ja/tai hyväksymään lähettämäsi pyyntö.")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }