<template>
  <v-container>
    <v-row class="justify-center">
      <v-col class="text-center">
        <h1 class="mt-5">Valitettavasti sivua ei löytynyt</h1>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style>
</style>