<template>
  <div>
    <v-app-bar app color="primary" dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" class="hidden-md-and-up"></v-app-bar-nav-icon>
      <v-toolbar-title>ERP / CRM</v-toolbar-title>

      <!-- Admin menu -->
      <v-toolbar-items v-if="hasRole == 'admin'" class="ml-4 hidden-sm-and-down">
        <div v-for="(link, index) in adminMenu" :key="'a' + index">
          <router-link
            v-if="isLoggedIn && link.title !== 'Asiakkaat' && link.title !== 'Asetukset'"
            :to="link.a"
          >
            <v-btn height="100%" text>{{ link.title }}</v-btn>
          </router-link>

          <!-- Asiakkaat dropdown -->
          <div class="fill-height" v-if="isLoggedIn && link.title == 'Asiakkaat'">
            <v-menu text open-on-hover>
              <template v-slot:activator="{ on, attrs }">
                <v-btn height="100%" text v-bind="attrs" v-on="on">
                  {{ link.title }}
                  <v-icon class="ml-1" small>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <div v-for="(li, index) in link.links" :key="index">
                  <router-link style="text-decoration: none" :to="li.a">
                    <v-list-item link>
                      <v-list-item-title link>{{ li.title }}</v-list-item-title>
                    </v-list-item>
                  </router-link>
                </div>
              </v-list>
            </v-menu>
          </div>

          <!-- Asetukset dropdown -->
          <div class="fill-height" v-if="isLoggedIn && link.title == 'Asetukset'">
            <v-menu text open-on-hover>
              <template v-slot:activator="{ on, attrs }">
                <v-btn height="100%" text v-bind="attrs" v-on="on">
                  {{ link.title }}
                  <v-icon class="ml-1" small>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <div v-for="(li, index) in link.links" :key="index">
                  <router-link style="text-decoration: none" :to="li.a">
                    <v-list-item link>
                      <v-list-item-title link>{{ li.title }}</v-list-item-title>
                    </v-list-item>
                  </router-link>
                </div>
              </v-list>
            </v-menu>
          </div>
        </div>
      </v-toolbar-items>

      <!-- User menu -->
      <v-toolbar-items v-if="hasRole == 'user'" class="ml-4 hidden-sm-and-down">
        <div v-for="(link, index) in userMenu" :key="'b' + index">
          <router-link v-if="isLoggedIn" :to="link.a">
            <v-btn height="100%" text>{{ link.title }}</v-btn>
          </router-link>
        </div>
      </v-toolbar-items>

      <v-spacer></v-spacer>

      <v-menu
        v-if="hasRole == 'user'"
        v-model="menu"
        :close-on-content-click="false"
        :nudge-width="300"
        offset-y
        bottom
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="mr-4" text v-bind="attrs" v-on="on">
            <v-icon medium>mdi-cart</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Ostoslista</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon icon>mdi-cart</v-icon>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-list>
            <v-list-item v-for="(product, index) in products" :key="index">
              <v-list-item-content>
                <v-list-item-title class="product-item-title">
                  Tuotenumero:{{ product.productId }}, {{ product.name }}
                  <span
                    @click="deleteItem(product)"
                    style="color: red; cursor: pointer"
                    title="Poista ostoskorista"
                  >X</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              v-if="products.length > 0"
              color="success"
              @click="$router.push('/user/checkout')"
            >Kysy saatavuutta</v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>

      <router-link to="/login">
        <v-btn text v-if="!isLoggedIn">Kirjaudu sisään</v-btn>
      </router-link>
      <router-link to="/logout">
        <v-btn text v-if="isLoggedIn">Kirjaudu ulos</v-btn>
      </router-link>
    </v-app-bar>

    <!-- Navigation drawer -->
    <v-navigation-drawer v-model="drawer" fixed temporary>
      <v-list-item>
        <v-list-item>
          <h2>ERP / CRM</h2>
        </v-list-item>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense>
        <!-- Admin menu -->
        <div v-for="(link, index) in adminMenu" :key="'c' + index">
          <v-list-item-content v-if="isLoggedIn && hasRole == 'admin'">
            <!-- Ei dropdown linkit  -->
            <router-link
              style="text-decoration: none;"
              :to="link.a"
              v-if="link.title !== 'Asiakkaat' && link.title !== 'Asetukset'"
            >
              <v-list-item link>
                <v-list-item-title>{{ link.title }}</v-list-item-title>
              </v-list-item>
            </router-link>

            <!-- Asiakkaat dropdown  -->
            <div v-if="link.title == 'Asiakkaat'">
              <v-menu open-on-hover>
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item link>
                    <v-list-item-title v-bind="attrs" v-on="on">
                      {{ link.title }}
                      <v-icon class="ml-1" small>mdi-chevron-down</v-icon>
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <v-list dense>
                  <div v-for="(li, index) in link.links" :key="'y' + index">
                    <router-link style="text-decoration: none" :to="li.a">
                      <v-list-item link>
                        <v-list-item-title>{{ li.title }}</v-list-item-title>
                      </v-list-item>
                    </router-link>
                  </div>
                </v-list>
              </v-menu>
            </div>

            <!-- Asetukset dropdown  -->
            <div v-if="link.title == 'Asetukset'">
              <v-menu open-on-hover>
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item link>
                    <v-list-item-title v-bind="attrs" v-on="on">
                      {{ link.title }}
                      <v-icon class="ml-1" small>mdi-chevron-down</v-icon>
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <v-list dense>
                  <div v-for="(li, index) in link.links" :key="'y' + index">
                    <router-link style="text-decoration: none" :to="li.a">
                      <v-list-item link>
                        <v-list-item-title>{{ li.title }}</v-list-item-title>
                      </v-list-item>
                    </router-link>
                  </div>
                </v-list>
              </v-menu>
            </div>
          </v-list-item-content>
        </div>

        <!-- User menu -->
        <div v-for="(link, index) in userMenu" :key="'d' + index">
          <v-list-item-content v-if="isLoggedIn && hasRole == 'user'">
            <router-link style="text-decoration: none" :to="link.a">
              <v-list-item link>
                <v-list-item-title>{{ link.title }}</v-list-item-title>
              </v-list-item>
            </router-link>
          </v-list-item-content>
        </div>
      </v-list>
    </v-navigation-drawer>

    <!-- snackbar -->
    <v-snackbar v-model="snackbar" :color="color" bottom right :timeout="5000">
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar = false">Sulje</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { snacbarBus, eventBus } from "../main";

export default {
  data: () => {
    return {
      menu: false,
      quantity: null,
      drawer: null,
      adminMenu: [
        { title: "Laskut", a: "/invoices" },
        { title: "Profiilit", a: "/profiles" },
        {
          title: "Asiakkaat",
          links: [
            { title: "Asiakkaat", a: "/customers" },
            { title: "Ryhmät", a: "/groups" }
          ]
        },
        { title: "Tuotteet", a: "/products" },
        { title: "Tilaukset", a: "/orders" },
        { title: "Tilastot", a: "/statistics" },

        {
          title: "Asetukset",
          links: [
            { title: "Asetukset", a: "/settings" },
            { title: "Maksumuistutukset", a: "/reminders" }
          ]
        }
      ],
      userMenu: [
        { title: "Laskuni", a: "/user/invoices" },
        { title: "Tilaa", a: "/user/order" },
        { title: "Tilastot", a: "/user/statistics" },
        { title: "Asetukset", a: "/user/settings" }
      ],
      snackbar: false,
      color: "success",
      text: ""
    };
  },
  created() {
    snacbarBus.$on("createSnackbar", this.showSnackbar);
    eventBus.$on("showCart", () => {
      this.menu = true;
      setTimeout(() => {
        this.menu = false;
      }, 4000);
    });
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
    hasRole() {
      return this.$store.getters.getRole;
    },
    products() {
      return this.$store.getters.getCart;
    }
  },
  methods: {
    showSnackbar(value) {
      this.color = value.color;
      this.text = value.text;
      this.snackbar = true;
    },
    deleteItem(item) {
      this.$store.commit("removeFromCart", item);
    }
  }
};
</script>

<style scoped>
.snacbar-container {
  position: absolute;
}
.close-snackbar {
  position: relative;
  top: 0px;
  right: 0px;
}

.product-item-title {
  position: relative;
}
.product-item-title span {
  position: absolute;
  right: 10px;
}

.quantity-wrapper {
  display: flex;
  align-items: center;
  height: 100%;
}

.v-btn__content {
  font-weight: 500;
}
</style>
