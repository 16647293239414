import Vue from 'vue';
import VueRouter from 'vue-router';
import Profiles from '../views/Profile/Profiles.vue';
import NewProfile from '../views/Profile/NewProfile.vue';
import EditProfile from '../views/Profile/EditProfile.vue';
import Customers from '../views/Customer/Customers.vue';
import NewCustomer from '../views/Customer/NewCustomer.vue';
import EditCustomer from '../views/Customer/EditCustomer.vue';
import Groups from '../views/Group/Groups.vue';
import NewGroup from '../views/Group/NewGroup.vue';
import EditGroup from '../views/Group/EditGroup.vue';
import Invoices from '../views/Invoice/Invoices.vue';
import NewInvoice from '../views/Invoice/NewInvoice.vue';
import EditInvoice from '../views/Invoice/EditInvoice.vue';
import Products from '../views/Product/Products.vue';
import Orders from '../views/Order/Orders.vue';
import Statistics from '../views/Statistics/Statistics.vue';
import Settings from '../views/Settings/Settings.vue';
import Reminders from '../views/Settings/Reminders.vue';
import Login from '../views/Auth/Login.vue';
import Logout from '../views/Auth/Logout.vue';
import Register from '../views/Auth/Register.vue';
import PasswordForgot from '../views/Auth/PasswordForgot.vue';
import PasswordReset from '../views/Auth/PasswordReset.vue';
import NotFound404 from '../components/404.vue';
import store from '../store/index';
// User
import UserInvoices from '../views/UserViews/Invoice/UserInvoices.vue';
import UserProducts from '../views/UserViews/Order/UserProducts.vue';
import UserCheckout from '../views/UserViews/Order/UserCheckout.vue';
import UserStatistics from '../views/UserViews/Statistics/UserStatistics.vue';
import UserSettings from '../views/UserViews/Settings/UserSettings.vue';

Vue.use(VueRouter);

const routes = [
  { path: '/', redirect: '/login' },
  {
    path: '/login',
    name: 'Kirjautuminen',
    component: Login,
  },
  {
    path: '/logout',
    name: 'Uloskirjautuminen',
    component: Logout,
  },
  {
    path: '/register/:merchantId',
    name: 'Rekisteröityminen',
    component: Register,
  },
  {
    path: '/forgotpassword',
    name: 'Unohtuiko salasana?',
    component: PasswordForgot,
  },
  {
    path: '/resetpassword/:token',
    name: 'Salasanan uusiminen',
    component: PasswordReset,
  },
  {
    path: '/profiles',
    name: 'Profiilit',
    component: Profiles,
    meta: { authorize: ['admin'] },
  },
  {
    path: '/profile/create',
    name: 'Uusi profiili',
    component: NewProfile,
    meta: { authorize: ['admin'] },
  },
  {
    path: '/profile/edit/:id',
    name: 'Muokkaa profiilia',
    component: EditProfile,
    meta: { authorize: ['admin'] },
  },
  {
    path: '/customers',
    name: 'Asiakkaat',
    component: Customers,
    meta: { authorize: ['admin'] },
  },
  {
    path: '/customer/create',
    name: 'Uusi asiakas',
    component: NewCustomer,
    meta: { authorize: ['admin'] },
  },
  {
    path: '/customer/edit/:id',
    name: 'Muokkaa asiakasta',
    component: EditCustomer,
    meta: { authorize: ['admin'] },
  },
  {
    path: '/groups',
    name: 'Ryhmät',
    component: Groups,
    meta: { authorize: ['admin'] },
  },
  {
    path: '/group/create',
    name: 'Uusi ryhmä',
    component: NewGroup,
    meta: { authorize: ['admin'] },
  },
  {
    path: '/group/edit/:id',
    name: 'Muokkaa ryhmää',
    component: EditGroup,
    meta: { authorize: ['admin'] },
  },
  {
    path: '/invoices/',
    name: 'Laskut',
    component: Invoices,
    meta: { authorize: ['admin'] },
  },
  {
    path: '/invoice/create',
    name: 'Uusi lasku',
    component: NewInvoice,
    meta: { authorize: ['admin'] },
  },
  {
    path: '/invoice/edit/:id',
    name: 'Muokkaa laskua',
    component: EditInvoice,
    meta: { authorize: ['admin'] },
  },
  {
    path: '/products/',
    name: 'Tuotteet',
    component: Products,
    meta: { authorize: ['admin'] },
  },
  {
    path: '/orders/',
    name: 'Tilaukset',
    component: Orders,
    meta: { authorize: ['admin'] },
  },
  {
    path: '/statistics',
    name: 'Tilastot',
    component: Statistics,
    meta: { authorize: ['admin'] },
  },
  {
    path: '/settings',
    name: 'Asetukset',
    component: Settings,
    meta: { authorize: ['admin'] },
  },
  {
    path: '/Reminders',
    name: 'Maksumuistutukset',
    component: Reminders,
    meta: { authorize: ['admin'] },
  },

  // USER
  //
  {
    path: '/user/invoices',
    name: 'Omat laskuni',
    component: UserInvoices,
    meta: { authorize: ['user', 'userNoProducts'] },
  },
  {
    path: '/user/order',
    name: 'Tee tilaus',
    component: UserProducts,
    meta: { authorize: ['user'] },
  },
  {
    path: '/user/checkout',
    name: 'Kassa',
    component: UserCheckout,
    meta: { authorize: ['user'] },
  },
  {
    path: '/user/statistics',
    name: 'Asiakastilastot',
    component: UserStatistics,
    meta: { authorize: ['user', 'userNoProducts'] },
  },
  {
    path: '/user/settings',
    name: 'Käyttäjän asetukset',
    component: UserSettings,
    meta: { authorize: ['user', 'userNoProducts'] },
  },

  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  // },
  { path: '*', component: NotFound404 },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  // IF AUTHORIZE DATA
  if (to.meta.authorize) {
    // No user or no token
    if (!store.state.user || !store.state.token) {
      await store.dispatch('deleteToken');
      return next('/login');
    } else {
      if (to.meta.authorize.includes(store.getters.getRole)) {
        return next();
      } else {
        return next(from);
      }
    }
  }
  //  Other routes without authorize data
  next();
});

export default router;
