<template>
  <v-container>
    <div class="mt-5">
      <v-data-table :headers="headers" :items="profiles" sort-by="calories">
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Laskutusprofiilit</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>

            <router-link to="/profile/create">
              <v-btn color="primary" dark class="mb-2">Uusi profiili</v-btn>
            </router-link>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-5" @click="editItem(item)">mdi-pencil</v-icon>
          <!-- <v-icon small @click="deleteItem(item)">mdi-delete</v-icon> -->
        </template>
        <template v-slot:no-data>
          <!-- <v-btn color="primary" @click="initialize">Reset</v-btn> -->
          <h2>Ei profiileja</h2>
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>

<script>
import axiosMethods from "../../mixins/axios";
import mixins from "../../mixins/mixins";

export default {
  title: "Profiilit",
  mixins: [mixins],
  data: () => ({
    dialog: false,
    headers: [
      {
        text: "Nimi",
        value: "name",
      },
      {
        text: "Tyyppi",
        value: "isCompany",
      },
      { text: "Y-tunnus", value: "businessId" },
      { text: "Email", value: "email" },
      { text: "Toiminnot", value: "actions", sortable: false },
    ],
    profiles: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
  }),
  async created() {
    try {
      let res = await axiosMethods.get("/api/v1/profile/getAll");
      let profiles = res.data.data;

      profiles = profiles.map((el) => {
        el.isCompany = this.formatIsCompany(el.isCompany);
        return el;
      });

      this.profiles = profiles;
    } catch (err) {
      this.showPopup(err, "error");
    }
  },
  methods: {
    editItem(item) {
      this.$router.push("/profile/edit/" + item._id);
    },
    // async deleteItem(item) {
    //   if (confirm("Haluatko varmasti poistaa: " + item.name)) {
    //     try {
    //       await axiosMethods.delete("/api/v1/profile/delete/" + item._id);
    //       this.showPopup("Profiili poistettu", "success");
    //       this.$router.go();
    //     } catch (err) {
    //       this.showPopup(err, "error");
    //     }
    //   }
    // },
  },
};
</script>

<style></style>
