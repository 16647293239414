<script>
import { Bar } from "vue-chartjs";

export default {
  extends: Bar,

  props: ["options", "yearlyData", "yearLabels"],

  data() {
    return {};
  },

  mounted() {
    // Overwriting base render method with actual data.
    this.renderChart(
      {
        labels: this.yearLabels,
        datasets: [
          {
            label: `Vuosittaiset myynnit`,
            backgroundColor: "#f87979",
            data: this.yearlyData,
          },
        ],
      },
      this.options
    );
  },

  // methods: {

  // },
};
</script>

<style></style>
