<template>
  <v-container>
    <!-- Asetukset -->
    <h1 class="mt-5">Asetukset</h1>
    <v-form ref="form">
      <v-row>
        <v-col cols="12" md="4" sm="5">
          <v-text-field v-model="name" label="Nimi"></v-text-field>
        </v-col>
        <v-col cols="12" md="4" sm="5">
          <v-text-field v-model="email" label="Käyttäjätunnus / Email"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4" sm="5">
          <v-checkbox v-model="useReferenceNumber" label="Käytä viitenumeroa laskuilla"></v-checkbox>
          <v-checkbox v-model="useLogo" label="Käytä logoa laskulla"></v-checkbox>
        </v-col>
        <v-col cols="12" md="3" sm="5">
          <v-text-field v-model.number="nextInvoiceNumber" type="number" label="Seuraavan laskun numero"></v-text-field>
        </v-col>
        <v-col cols="12" md="3" sm="5">
          <v-text-field v-model.number="reminderInterval" type="number" label="Maksumuistutusten intervalli"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn color="success" class="mr-4 mt-5" @click="updateUserData">Tallenna käyttäjätiedot</v-btn>
        </v-col>
      </v-row>
    </v-form>

    <br />
    <hr />
    <!-- Salasana -->
    <h2 class="mt-5">Päivitä salasana</h2>
    <v-form ref="form">
      <v-row>
        <v-col cols="12" md="4" sm="5">
          <v-text-field v-model="password" type="password" label="Uusi salasana"></v-text-field>
        </v-col>
        <v-col cols="12" md="4" sm="5">
          <v-text-field v-model="passwordConfirm" type="password" label="Toista salasana"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="4" sm="5">
          <v-text-field v-model="currentPassword" type="password" label="Nykyinen salasana"></v-text-field>
          <small>Päivittääksesi salasanasi, sinun tulee antaa myös nykyinen salasanasi</small>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn color="success" class="mr-4 mt-5" @click="updatePassword">Päivitä salasana</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import axiosMethods from '../../mixins/axios';
import mixins from '../../mixins/mixins';

export default {
  title: 'Asetukset',
  mixins: [mixins],
  data: () => {
    return {
      name: '',
      email: '',
      password: '',
      passwordConfirm: '',
      currentPassword: '',
      useReferenceNumber: true,
      reminderInterval: '',
      nextInvoiceNumber: '',
      useLogo: false,
    };
  },
  async created() {
    try {
      let res1 = await axiosMethods.get('/api/v1/invoice/getNextInvoiceNumber');
      let res2 = await axiosMethods.get('/api/v1/user/getCurrentUser');
      this.name = res2.data.currentUser.name;
      this.email = res2.data.currentUser.email;
      this.nextInvoiceNumber = res1.data.invoiceNumber;
      this.useReferenceNumber = res2.data.currentUser.useReferenceNumber;
      this.reminderInterval = res2.data.currentUser.reminderInterval;
      this.useLogo = res2.data.currentUser.useLogo;
    } catch (err) {
      axiosMethods.validateRequest(err);
      this.showPopup(err, 'error');
    }
  },
  methods: {
    async updateUserData() {
      try {
        await axiosMethods.updateOne('/api/v1/user/updateUser', {
          nextInvoiceNumber: this.nextInvoiceNumber,
          useReferenceNumber: this.useReferenceNumber,
          reminderInterval: this.reminderInterval,
          useLogo: this.useLogo,
          email: this.email,
          name: this.name,
        });
        this.$router.go();
        this.showPopup('Tiedot päivitetty', 'success');
      } catch (err) {
        this.$router.go();
        this.showPopup(err, 'error');
      }
    },
    async updatePassword() {
      try {
        let res = await axiosMethods.updateOne('/api/v1/user/updateMyPassword', {
          password: this.password,
          passwordConfirm: this.passwordConfirm,
          currentPassword: this.currentPassword,
        });
        let token = res.data.token;
        localStorage.setItem('token', token);
        this.$store.commit('setToken', token);

        // this.$router.go();
        this.showPopup('Salasana päivitetty', 'success');
        this.password = '';
        this.passwordConfirm = '';
        this.currentPassword = '';
      } catch (err) {
        this.showPopup(err, 'error');
        this.password = '';
        this.passwordConfirm = '';
        this.currentPassword = '';
      }
    },
  },
};
</script>

<style></style>
