import axiosMethods from "../mixins/axios";

export default {
  async getAllInvoices(url) {
    return await axiosMethods.get(url);
  },

  async deleteInvoice(id) {
    return await axiosMethods.delete("/api/v1/invoice/delete/" + id);
  },

};
