<template>
  <v-container>
    <div class="mt-5">
      <v-row class="justify-center">
        <v-col md="4">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Etsi"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>

      <v-data-table :search="search" :headers="headers" :items="groups" v-model="selected">
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Ryhmät</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <router-link to="/group/create">
              <v-btn color="primary" dark class="mb-2">Uusi ryhmä</v-btn>
            </router-link>
          </v-toolbar>
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-5" @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
        </template>

        <template v-slot:no-data>
          <h2>Ei ryhmiä</h2>
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>

<script>
import axiosMethods from "../../mixins/axios";
import mixins from "../../mixins/mixins";

export default {
  title: "Ryhmät",
  mixins: [mixins],
  data: () => ({
    dialog: false,
    group: {},
    selected: [],
    search: "",
    headers: [
      {
        text: "Nimi",
        value: "name",
      },
      { text: "Toiminnot", value: "actions", sortable: false },
    ],
    groups: [],
  }),
  async created() {
    try {
      let res = await axiosMethods.get("/api/v1/group/getAll");
      this.groups = res.data.groups;
    } catch (err) {
      this.showPopup(err, "error");
    }
  },
  methods: {
    editItem(item) {
      this.$router.push("/group/edit/" + item._id);
    },
    async deleteItem(item) {
      if (confirm("Haluatko varmasti poistaa ryhmän " + item.name + "?")) {
        try {
          await axiosMethods.delete("/api/v1/group/delete/" + item._id);
          this.showPopup("Ryhmä poistettu", "success");
          this.$router.go();
        } catch (err) {
          this.showPopup(err, "error");
        }
      }
    },
    formatMessageSend(item) {
      return item == true ? "Kyllä" : "Ei";
    },
  },
};
</script>

<style></style>
