<template>
  <v-card tile class="mx-auto">
    <v-list
      :disabled="disabled"
      :dense="dense"
      :two-line="twoLine"
      :three-line="threeLine"
      :shaped="shaped"
      :flat="flat"
      :subheader="subheader"
      :sub-group="subGroup"
      :nav="nav"
      :avatar="avatar"
      :rounded="rounded"
    >
      <v-subheader class="ml-5">{{ title }}</v-subheader>
      <v-list-item-group color="primary">
        <v-list-item v-for="(item, index) in items" :key="index" :inactive="inactive">
          <v-list-item-content class="ml-5 content-wrapper">
            <v-list-item-title class="mb-1">{{ item.title }}</v-list-item-title>
            <v-list-item-subtitle class="subtitle" v-if="title == 'LASKUTUS'"
              >{{ item.count }} kpl / {{ formatPrice(item.totalAmount) }}</v-list-item-subtitle
            >
            <v-list-item-subtitle class="subtitle" v-if="title == 'ASIAKKAAT'">{{ item.count }} kpl</v-list-item-subtitle>
            <v-list-item-subtitle class="subtitle" v-if="title == 'MYYNTI'">{{ formatPrice(item.totalAmount) }}</v-list-item-subtitle>
            <v-list-item-subtitle class="subtitle" v-if="title == 'TUOTTEET'">{{ item.count }} kpl</v-list-item-subtitle>
            <v-list-item-subtitle class="subtitle" v-if="title == 'KASSAVIRTA'">{{ formatPrice(item.totalAmount) }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>

    <!-- Top 5 products -->
    <v-list
      v-if="title == 'TUOTTEET' && products.length > 0"
      class="v-list-top"
      :disabled="disabled"
      :dense="dense"
      :two-line="twoLine"
      :three-line="threeLine"
      :shaped="shaped"
      :flat="flat"
      :subheader="subheader"
      :sub-group="subGroup"
      :nav="nav"
      :avatar="avatar"
      :rounded="rounded"
    >
      <v-list-group class="ml-5" no-action :value="false">
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>{{ top10Products }} (alv 0)</v-list-item-title>
          </v-list-item-content>
        </template>

        <div class="ml-5" v-for="(item, index) in products" :key="index" :inactive="inactive">
          <v-list-item-subtitle class="py-1 top-list-item">{{ item.name }}</v-list-item-subtitle>
          <v-list-item-subtitle class="top-list-subitem">{{ formatPrice(item.totalAmount) }} | {{ item.count }} kpl</v-list-item-subtitle>
        </div>
      </v-list-group>
    </v-list>

    <!-- Top 10 customers -->
    <v-list
      v-if="title == 'ASIAKKAAT' && customers.length > 0"
      class="v-list-top"
      :disabled="disabled"
      :dense="dense"
      :two-line="twoLine"
      :three-line="threeLine"
      :shaped="shaped"
      :flat="flat"
      :subheader="subheader"
      :sub-group="subGroup"
      :nav="nav"
      :avatar="avatar"
      :rounded="rounded"
    >
      <v-list-group class="ml-5" no-action :value="false">
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>{{ top10Customers }} (alv 0)</v-list-item-title>
          </v-list-item-content>
        </template>

        <div class="ml-5" v-for="(item, index) in customers" :key="index" :inactive="inactive">
          <v-list-item-subtitle class="py-1 top-list-item">{{ item.name }}</v-list-item-subtitle>
          <v-list-item-subtitle class="top-list-subitem">{{ formatPrice(item.totalAmount) }}</v-list-item-subtitle>
        </div>
      </v-list-group>
    </v-list>
  </v-card>
</template>

<script>
import mixins from '../../mixins/mixins';

export default {
  mixins: [mixins],
  props: {
    title: String,
    items: Array,
    products: Array,
    customers: Array,
    top10Customers: String,
    top10Products: String,
  },
  data() {
    return {
      disabled: false,
      dense: false,
      twoLine: true,
      threeLine: false,
      shaped: false,
      flat: true,
      subheader: true,
      inactive: true,
      subGroup: false,
      nav: true,
      avatar: false,
      rounded: false,
    };
  },
};
</script>

<style scoped>
.subtitle {
  color: rgb(134, 134, 134) !important;
  font-size: 15px;
}

.v-list-top {
  margin-top: -15px;
}

.top-list-item {
  font-size: 0.95em !important;
  color: rgb(70, 70, 70) !important;
  font-size: 12px;
}

.top-list-subitem {
  font-size: 0.9em;
  color: rgb(90, 90, 90) !important;
}

.content-wrapper {
  border-bottom: 1px solid rgb(218, 217, 217);
  margin-bottom: 5px;
  padding-left: 5px;
}
</style>
